import React, { useEffect, useState } from "react";
import CloseImg from "../../../assets/img/cancel.svg";
import PortfolioData from "../portfolioData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./modal.css";
import Video from "../../Video";
import HtmlParser from "../../HtmlParser";

const Modals = ({ item }) => {
  console.log(item);
  let type = item.type.split(",") ? item.type.split(",").join(", ") : item.type;

  return (
    <div className="box_inner blog-post pb30px">
      <article>
        <div className="title-section text-left text-sm-center">
          <h1>
            Подробности <br />
            <span>документа</span>
          </h1>
          <span className="title-bg">Факты</span>
        </div>
        <h3 className="heading mb-2">{item.title}</h3>
        <div className="col-12 col-sm-12 mb-4">
          <HtmlParser html={item.desc} />
        </div>
        <div className="row open-sans-font mb-4">
          <div className="col-12 col-sm-6 mb-2">
            <i className="fa fa-tags pr-2"></i>
            Тип: <span className="ft-wt-600 uppercase">{type}</span>
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <i className="fa fa-user-circle-o pr-2"></i>
            Источник:{" "}
            <span className="ft-wt-600 uppercase">{item.executor}</span>
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <i className="fa fa-calendar pr-2"></i>
            Дата: <span className="ft-wt-600 uppercase">{item.date}</span>
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <i className="fa fa-link pr-2"></i>
            Ссылка:{" "}
            <a
              className="preview-link"
              target="_blank"
              rel="noopener noreferrer nofollow"
              href={item.link}
            >
              {item.linkname}
            </a>
          </div>
        </div>
        <div className="imgaList">
          {item.img.split(" ").map((elem, index) => {
            if (index) {
              return (
                <div key={index} className="modal__img">
                  <Video src={`${process.env.REACT_APP_API_KEY}/${elem}`} />
                </div>
              );
            }
          })}
        </div>
      </article>
    </div>
  );
};

export default Modals;
