import React from "react";

const personalInfoContent = [
  { meta: "наименование", metaInfo: "ЖК Бастион" },
  { meta: "наименование", metaInfo: "МФК Маршал" },
  { meta: "архитектор", metaInfo: "Михаил Филлипов" },
  { meta: "архитектор", metaInfo: "Александр Балабин" },
  { meta: "застройка", metaInfo: "2006-2012" },
  { meta: "застройка", metaInfo: "2008-2016" },
  { meta: "этажность", metaInfo: "3-17" },
  { meta: "этажность", metaInfo: "7-19" },
  { meta: "площадь", metaInfo: "140000 кв.м" },
  { meta: "площадь", metaInfo: "200000 кв.м" },
  { meta: "офисы", metaInfo: "42000 кв.м" },
  { meta: "офисы", metaInfo: "65000 кв.м" },
  { meta: "отель", metaInfo: "7307 кв.м" },
  { meta: "отель", metaInfo: "15000 кв.м" },
  { meta: "кафе", metaInfo: "2650 кв.м" },
  { meta: "кафе", metaInfo: "500 кв.м" },
  { meta: "фитнес", metaInfo: "2800 кв.м" },
  { meta: "фитнес", metaInfo: "0 кв.м" },
  { meta: "детсад", metaInfo: "2098 кв.м" },
  { meta: "детсад", metaInfo: "0 кв.м" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
