import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modals from "./modal/Modal";
import axios from "axios";
import "../advertisment/advert.css";
import Video from "../Video";
import Modal from "react-modal";
import cancelImg from "../../assets/img/cancel.svg";
const Portfolio = () => {
  const [getModal, setGetModal] = useState(false);
  const [modalId, setModalId] = useState(1);
  const [PortfolioData, setPortfolioData] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [item, setItem] = useState({});

  const handleModal = (id) => {
    setGetModal(true);
    setModalId(id);
  };

  useEffect(() => {
    const fetchedData = async () => {
      const bebra = await axios.get(
        `${process.env.REACT_APP_API_KEY}/doc/docs`
      );
      if (bebra.status === 200) {
        setPortfolioData(bebra.data.reverse());

        setFetched(true);
      }
    };

    fetchedData();
  }, []);

  let tags = [];
  PortfolioData.map((i) => {
    if (i.type.split(",").length > 1) {
      tags.push(...i.type.split(","));
    } else {
      tags.push(i.type);
    }
  });
  const currentTags = [...new Set(tags)];
  const Tb = currentTags.map((i, index) => {
    return { name: i, data: [] };
  });

  let randomData = PortfolioData.map((i) => i);
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  shuffle(randomData);

  PortfolioData.filter((i) => {
    if (i.type.split(",").length > 1) {
      for (const key in Tb) {
        if (Object.hasOwnProperty.call(Tb, key)) {
          const element = Tb[key];
          i.type.split(",").map((ii) => {
            if (element.name == ii) {
              console.log(element.data.push(i));
            }
          });
        }
      }
    } else {
      for (const key in Tb) {
        if (Object.hasOwnProperty.call(Tb, key)) {
          const element = Tb[key];
          i.type.split(",").map((ii) => {
            if (element.name == ii) {
              console.log(element.data.push(i));
            }
          });
        }
      }
    }
  });
  console.log(Tb);
  return (
    <>
      {fetched ? (
        <div className="portfolio-main">
          <Tabs>
            <TabList className="portfolio-tab-list" data-aos="fade-up">
              <Tab>Все</Tab>
              <Tab>Новые</Tab>
              {Tb.map((elem, index) => (
                <Tab key={index}>{elem.name}</Tab>
              ))}
            </TabList>

            <div className="container">
              <TabPanel>
                <div className="tab-container">
                  {randomData.map((item) => {
                    const { id, type } = item;

                    return (
                      <div key={id} data-aos="fade-right">
                        <div
                          className="tab-content"
                          onClick={() => {
                            setModalActive(true);
                            setItem(item);
                          }}
                        >
                          <Video
                            src={`${process.env.REACT_APP_API_KEY}/${
                              item.img.split(" ")[1]
                            }`}
                          />
                          <h3>
                            <span className="conent-title">{item.title}</span>
                          </h3>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="tab-container">
                  {PortfolioData.map((item) => {
                    const { id, type } = item;

                    return (
                      <div key={id} data-aos="fade-right">
                        <div
                          className="tab-content"
                          onClick={() => {
                            setModalActive(true);
                            setItem(item);
                          }}
                        >
                          <Video
                            src={`${process.env.REACT_APP_API_KEY}/${
                              item.img.split(" ")[1]
                            }`}
                          />
                          <h3>
                            <span className="conent-title">{item.title}</span>
                          </h3>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPanel>

              {Tb.map((elem, index) => (
                <TabPanel key={index}>
                  <div className="tab-container">
                    {elem.data.map((item) => {
                      const { id, type } = item;
                      return (
                        <div key={id} data-aos="fade-right">
                          <div
                            className="tab-content"
                            onClick={() => {
                              setModalActive(true);
                              setItem(item);
                            }}
                          >
                            <Video
                              src={`${process.env.REACT_APP_API_KEY}/${
                                item.img.split(" ")[1]
                              }`}
                            />

                            <h3>
                              <span className="conent-title">{item.title}</span>
                            </h3>
                          </div>
                          {/* {getModal && <Modal props={modalId} />} */}
                        </div>
                      );
                    })}
                  </div>
                </TabPanel>
              ))}
            </div>
            <Modal
              isOpen={modalActive}
              onRequestClose={() => {
                setModalActive(!modalActive);
              }}
              contentLabel="My dialog"
              className="custom-modal green"
              overlayClassName="custom-overlay light"
              closeTimeoutMS={500}
            >
              <div>
                <button
                  className="close-modal"
                  onClick={() => setModalActive(false)}
                >
                  <img src={cancelImg} alt="close icon" />
                </button>

                <Modals item={item} />
              </div>
            </Modal>

            {/* {modalActive ? (
              
            ) : null} */}
          </Tabs>
        </div>
      ) : null}
    </>
  );
};

export default Portfolio;
