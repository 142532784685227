import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import cancelImg from "../../assets/img/cancel.svg";
import UseData from "../../Hooks/UseData";
import dayjs from "dayjs";
import ReactPlayer from "react-player";
import Video from "../Video";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import HtmlParser from "../HtmlParser";
import TextBlock from "./TextBlock";
import moment from "moment";
import "moment/locale/ru.js";
Modal.setAppElement("#root");

const BlogLight = () => {
  moment.locale("ru");
  const { singleData, isOpen, setIsOpen, blogsData, handleBlogsData, fetched } =
    UseData();
  const handleModle = (id) => {
    handleBlogsData(id);
  };
  const [item, setItem] = useState({});
  const [modalActive, setModalActive] = useState(false);

  let tags = [];
  blogsData.map((i) => {
    if (i.type.split(",").length > 1) {
      tags.push(...i.type.split(","));
    } else {
      tags.push(i.type);
    }
  });
  const currentTags = [...new Set(tags)];
  const Tb = currentTags.map((i, index) => {
    return { name: i, data: [] };
  });
  let randomData = blogsData.map((i) => i);
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  shuffle(randomData);
  blogsData.filter((i) => {
    if (i.type.split(",").length > 1) {
      for (const key in Tb) {
        if (Object.hasOwnProperty.call(Tb, key)) {
          const element = Tb[key];
          i.type.split(",").map((ii) => {
            if (element.name == ii) {
              console.log(element.data.push(i));
            }
          });
        }
      }
    } else {
      for (const key in Tb) {
        if (Object.hasOwnProperty.call(Tb, key)) {
          const element = Tb[key];
          i.type.split(",").map((ii) => {
            if (element.name == ii) {
              console.log(element.data.push(i));
            }
          });
        }
      }
    }
  });

  return (
    <div className="portfolio-main">
      <Tabs>
        <TabList className="portfolio-tab-list" data-aos="fade-up">
          <Tab>Все</Tab>
          <Tab>Новые</Tab>
          {Tb.map((elem, index) => (
            <Tab key={index}>{elem.name}</Tab>
          ))}
        </TabList>
        <TabPanel className="portfolio-tab-list" data-aos="fade-up">
          <div className="row">
            {randomData.map((item) => (
              <div
                key={item.id}
                data-aos="fade-right"
                className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30"
              >
                {" "}
                {fetched ? (
                  <article
                    className="post-container"
                    onClick={() => handleModle(item?.id)}
                  >
                    {item.img.split(" ")[0] === "" ? (
                      <div className="post-thumb">
                        <div className="d-block position-relative overflow-hidden">
                          <Video
                            src={`${process.env.REACT_APP_API_KEY}/${
                              item.img.split(" ")[1]
                            }`}
                            res={true}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="post-thumb">
                        <div className="d-block position-relative overflow-hidden">
                          <img
                            src={`${process.env.REACT_APP_API_KEY}/${item.img}`}
                            className="img-fluid"
                            alt="item.title"
                          />
                        </div>
                      </div>
                    )}

                    <div className="post-content">
                      <div className="entry-header hv">
                        <h3>{item?.title}</h3>
                      </div>
                    </div>
                  </article>
                ) : null}
                {/* Start ModalOneBlogContent */}
                <Modal
                  isOpen={isOpen}
                  onRequestClose={() => setIsOpen(false)}
                  contentLabel="My dialog"
                  className="custom-modal green"
                  overlayClassName="custom-overlay light"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button
                      className="close-modal"
                      onClick={() => setIsOpen(false)}
                    >
                      <img src={cancelImg} alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner blog-post">
                      {/* Article Starts */}
                      <article>
                        <div className="title-section text-left text-sm-center">
                          <h1>
                            Подробности <br />
                            <span>события</span>
                          </h1>
                          <span className="title-bg">новости</span>
                        </div>
                        {/* Meta Starts */}

                        {isOpen ? (
                          <>
                            {singleData.img.split(" ").length > 2 ? (
                              <>
                                <Video
                                  src={`${process.env.REACT_APP_API_KEY}/${
                                    singleData.img.split(" ")[1]
                                  }`}
                                />
                                <h3 className="heading mb-4 mt-4">
                                  {singleData?.title}
                                </h3>
                                <div className="meta open-sans-font">
                                  <span>
                                    <i className="fa fa-user-circle-o"></i>{" "}
                                    {singleData.company}
                                  </span>
                                  <span className="date">
                                    <i className="fa fa-calendar"></i>{" "}
                                    {moment(singleData.createdAt).format("LL")}
                                  </span>
                                  <span>
                                    <i className="fa fa-tags"></i>{" "}
                                    {singleData.type}
                                  </span>
                                </div>
                                <div className="blog-excerpt open-sans-font mb10">
                                  {/* <p>{singleData?.content}</p> */}
                                  <HtmlParser
                                    style={{ marginTop: "0px" }}
                                    html={singleData?.content}
                                  />
                                  <div>
                                    {singleData?.imgdesk1 ? (
                                      <TextBlock
                                        text={singleData?.desk1}
                                        img={singleData?.imgdesk1}
                                        top={singleData?.twoicons1}
                                        bot={singleData?.twoicons1bot}
                                      />
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk1}
                                      />
                                    )}
                                    {singleData?.imgdesk2 ? (
                                      <TextBlock
                                        text={singleData?.desk2}
                                        img={singleData?.imgdesk2}
                                        top={singleData?.twoicons2}
                                        bot={singleData?.twoicons2bot}
                                      />
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk2}
                                      />
                                    )}
                                    {singleData?.imgdesk3 ? (
                                      <TextBlock
                                        text={singleData?.desk3}
                                        img={singleData?.imgdesk3}
                                        top={singleData?.twoicons3}
                                        bot={singleData?.twoicons3bot}
                                      />
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk3}
                                      />
                                    )}
                                    {singleData?.imgdesk4 ? (
                                      <TextBlock
                                        text={singleData?.desk4}
                                        img={singleData?.imgdesk4}
                                        top={singleData?.twoicons4}
                                        bot={singleData?.twoicons4bot}
                                      />
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk4}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="imgaList">
                                  <Video
                                    src={`${process.env.REACT_APP_API_KEY}/${
                                      singleData.img.split(" ")[2]
                                    }`}
                                  />

                                  {singleData.img.split(" ")[3] && (
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${
                                        singleData.img.split(" ")[3]
                                      }`}
                                    />
                                  )}
                                  {singleData.img.split(" ")[4] && (
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${
                                        singleData.img.split(" ")[3]
                                      }`}
                                    />
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <Video
                                  src={`${process.env.REACT_APP_API_KEY}/${
                                    singleData.img.split(" ")[1]
                                  }`}
                                />
                                <div className="blog-excerpt open-sans-font pb-5">
                                  {/* <p>{singleData?.content}</p> */}

                                  <HtmlParser html={singleData?.content} />
                                  <div>
                                    {singleData?.imgdesk1 ? (
                                      <div className="quotebox">
                                        <div className="icon">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_KEY +
                                              "/" +
                                              singleData?.imgdesk1
                                            }
                                            alt="blog quote"
                                          />
                                        </div>
                                        <HtmlParser
                                          style={{ marginTop: "0px" }}
                                          html={singleData?.desk1}
                                        />
                                        {singleData?.twoicons1 ? (
                                          <div className="icon2">
                                            <img
                                              src={
                                                process.env.REACT_APP_API_KEY +
                                                "/" +
                                                singleData?.imgdesk1
                                              }
                                              alt="blog quote"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk1}
                                      />
                                    )}
                                    {singleData?.imgdesk2 ? (
                                      <div className="quotebox">
                                        <div className="icon">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_KEY +
                                              "/" +
                                              singleData?.imgdesk2
                                            }
                                            alt="blog quote"
                                          />
                                        </div>{" "}
                                        <HtmlParser
                                          style={{ marginTop: "0px" }}
                                          html={singleData?.desk2}
                                        />
                                        {singleData?.twoicons2 ? (
                                          <div className="icon2">
                                            <img
                                              src={
                                                process.env.REACT_APP_API_KEY +
                                                "/" +
                                                singleData?.imgdesk2
                                              }
                                              alt="blog quote"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk2}
                                      />
                                    )}
                                    {singleData?.imgdesk3 ? (
                                      <div className="quotebox">
                                        <div className="icon">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_KEY +
                                              "/" +
                                              singleData?.imgdesk3
                                            }
                                            alt="blog quote"
                                          />
                                        </div>{" "}
                                        <HtmlParser
                                          style={{ marginTop: "0px" }}
                                          html={singleData?.desk3}
                                        />
                                        {singleData?.twoicons3 ? (
                                          <div className="icon2">
                                            <img
                                              src={
                                                process.env.REACT_APP_API_KEY +
                                                "/" +
                                                singleData?.imgdesk3
                                              }
                                              alt="blog quote"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk3}
                                      />
                                    )}
                                    {singleData?.imgdesk4 ? (
                                      <div className="quotebox">
                                        <div className="icon">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_KEY +
                                              "/" +
                                              singleData?.imgdesk4
                                            }
                                            alt="blog quote"
                                          />
                                        </div>{" "}
                                        <HtmlParser
                                          style={{ marginTop: "0px" }}
                                          html={singleData?.desk4}
                                        />
                                        {singleData?.twoicons4 ? (
                                          <div className="icon2">
                                            <img
                                              src={
                                                process.env.REACT_APP_API_KEY +
                                                "/" +
                                                singleData?.imgdesk4
                                              }
                                              alt="blog quote"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk4}
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ) : null}

                        {/* Article Content Ends */}
                      </article>
                      {/* Article Ends */}
                    </div>
                  </div>
                </Modal>
                {/* End  ModalOneBlogContent */}
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            {blogsData.map((item) => (
              <div
                key={item.id}
                data-aos="fade-right"
                className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30"
              >
                {" "}
                {fetched ? (
                  <article
                    className="post-container"
                    onClick={() => handleModle(item?.id)}
                  >
                    {item.img.split(" ")[0] === "" ? (
                      <div className="post-thumb">
                        <div className="d-block position-relative overflow-hidden">
                          <Video
                            src={`${process.env.REACT_APP_API_KEY}/${
                              item.img.split(" ")[1]
                            }`}
                            res={true}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="post-thumb">
                        <div className="d-block position-relative overflow-hidden">
                          <img
                            src={`${process.env.REACT_APP_API_KEY}/${item.img}`}
                            className="img-fluid"
                            alt="item.title"
                          />
                        </div>
                      </div>
                    )}

                    <div className="post-content">
                      <div className="entry-header hv">
                        <h3>{item?.title}</h3>
                      </div>
                    </div>
                  </article>
                ) : null}
                {/* Start ModalOneBlogContent */}
                <Modal
                  isOpen={isOpen}
                  onRequestClose={() => setIsOpen(false)}
                  contentLabel="My dialog"
                  className="custom-modal green"
                  overlayClassName="custom-overlay light"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button
                      className="close-modal"
                      onClick={() => setIsOpen(false)}
                    >
                      <img src={cancelImg} alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner blog-post">
                      {/* Article Starts */}
                      <article>
                        <div className="title-section text-left text-sm-center">
                          <h1>
                            Подробности <span>события</span>
                          </h1>
                          <span className="title-bg">новости</span>
                        </div>
                        {/* Meta Starts */}

                        {isOpen ? (
                          <>
                            {singleData.img.split(" ").length > 2 ? (
                              <>
                                <Video
                                  src={`${process.env.REACT_APP_API_KEY}/${
                                    singleData.img.split(" ")[1]
                                  }`}
                                />
                                <h3 className="heading mb-4 mt-4">
                                  {singleData?.title}
                                </h3>

                                <div className="meta open-sans-font">
                                  <span>
                                    <i className="fa fa-user-circle-o"></i>{" "}
                                    {singleData.company}
                                  </span>
                                  <span className="date">
                                    <i className="fa fa-calendar"></i>{" "}
                                    {moment(singleData.createdAt).format("LL")}
                                  </span>
                                  <span>
                                    <i className="fa fa-tags"></i>{" "}
                                    {singleData.type}
                                  </span>
                                </div>
                                <div className="blog-excerpt open-sans-font mb10">
                                  {/* <p>{singleData?.content}</p> */}
                                  <HtmlParser
                                    style={{ marginTop: "0px" }}
                                    html={singleData?.content}
                                  />
                                  <div>
                                    {singleData?.imgdesk1 ? (
                                      <TextBlock
                                        text={singleData?.desk1}
                                        img={singleData?.imgdesk1}
                                        top={singleData?.twoicons1}
                                        bot={singleData?.twoicons1bot}
                                      />
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk1}
                                      />
                                    )}
                                    {singleData?.imgdesk2 ? (
                                      <TextBlock
                                        text={singleData?.desk2}
                                        img={singleData?.imgdesk2}
                                        top={singleData?.twoicons2}
                                        bot={singleData?.twoicons2bot}
                                      />
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk2}
                                      />
                                    )}
                                    {singleData?.imgdesk3 ? (
                                      <TextBlock
                                        text={singleData?.desk3}
                                        img={singleData?.imgdesk3}
                                        top={singleData?.twoicons3}
                                        bot={singleData?.twoicons3bot}
                                      />
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk3}
                                      />
                                    )}
                                    {singleData?.imgdesk4 ? (
                                      <TextBlock
                                        text={singleData?.desk4}
                                        img={singleData?.imgdesk4}
                                        top={singleData?.twoicons4}
                                        bot={singleData?.twoicons4bot}
                                      />
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk4}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="imgaList">
                                  <Video
                                    src={`${process.env.REACT_APP_API_KEY}/${
                                      singleData.img.split(" ")[2]
                                    }`}
                                  />

                                  {singleData.img.split(" ")[3] && (
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${
                                        singleData.img.split(" ")[3]
                                      }`}
                                    />
                                  )}
                                  {singleData.img.split(" ")[4] && (
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${
                                        singleData.img.split(" ")[3]
                                      }`}
                                    />
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <Video
                                  src={`${process.env.REACT_APP_API_KEY}/${
                                    singleData.img.split(" ")[1]
                                  }`}
                                />
                                <div className="blog-excerpt open-sans-font pb-5">
                                  {/* <p>{singleData?.content}</p> */}

                                  <HtmlParser html={singleData?.content} />
                                  <div>
                                    {singleData?.imgdesk1 ? (
                                      <div className="quotebox">
                                        <div className="icon">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_KEY +
                                              "/" +
                                              singleData?.imgdesk1
                                            }
                                            alt="blog quote"
                                          />
                                        </div>
                                        <HtmlParser
                                          style={{ marginTop: "0px" }}
                                          html={singleData?.desk1}
                                        />
                                        {singleData?.twoicons1 ? (
                                          <div className="icon2">
                                            <img
                                              src={
                                                process.env.REACT_APP_API_KEY +
                                                "/" +
                                                singleData?.imgdesk1
                                              }
                                              alt="blog quote"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk1}
                                      />
                                    )}
                                    {singleData?.imgdesk2 ? (
                                      <div className="quotebox">
                                        <div className="icon">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_KEY +
                                              "/" +
                                              singleData?.imgdesk2
                                            }
                                            alt="blog quote"
                                          />
                                        </div>{" "}
                                        <HtmlParser
                                          style={{ marginTop: "0px" }}
                                          html={singleData?.desk2}
                                        />
                                        {singleData?.twoicons2 ? (
                                          <div className="icon2">
                                            <img
                                              src={
                                                process.env.REACT_APP_API_KEY +
                                                "/" +
                                                singleData?.imgdesk2
                                              }
                                              alt="blog quote"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk2}
                                      />
                                    )}
                                    {singleData?.imgdesk3 ? (
                                      <div className="quotebox">
                                        <div className="icon">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_KEY +
                                              "/" +
                                              singleData?.imgdesk3
                                            }
                                            alt="blog quote"
                                          />
                                        </div>{" "}
                                        <HtmlParser
                                          style={{ marginTop: "0px" }}
                                          html={singleData?.desk3}
                                        />
                                        {singleData?.twoicons3 ? (
                                          <div className="icon2">
                                            <img
                                              src={
                                                process.env.REACT_APP_API_KEY +
                                                "/" +
                                                singleData?.imgdesk3
                                              }
                                              alt="blog quote"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk3}
                                      />
                                    )}
                                    {singleData?.imgdesk4 ? (
                                      <div className="quotebox">
                                        <div className="icon">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_KEY +
                                              "/" +
                                              singleData?.imgdesk4
                                            }
                                            alt="blog quote"
                                          />
                                        </div>{" "}
                                        <HtmlParser
                                          style={{ marginTop: "0px" }}
                                          html={singleData?.desk4}
                                        />
                                        {singleData?.twoicons4 ? (
                                          <div className="icon2">
                                            <img
                                              src={
                                                process.env.REACT_APP_API_KEY +
                                                "/" +
                                                singleData?.imgdesk4
                                              }
                                              alt="blog quote"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <HtmlParser
                                        style={{ marginTop: "0px" }}
                                        html={singleData?.desk4}
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ) : null}

                        {/* Article Content Ends */}
                      </article>
                      {/* Article Ends */}
                    </div>
                  </div>
                </Modal>
                {/* End  ModalOneBlogContent */}
              </div>
            ))}
          </div>
        </TabPanel>
        {Tb.map((item, index) => {
          console.log(item);
          return (
            <TabPanel key={index}>
              <div className="row" data-aos="fade-right">
                {item.data.map((item) => {
                  return (
                    <div
                      key={item.id}
                      data-aos="fade-right"
                      className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30"
                    >
                      {" "}
                      {fetched ? (
                        <article
                          className="post-container"
                          onClick={() => handleModle(item?.id)}
                        >
                          {item.img.split(" ")[0] === "" ? (
                            <div className="post-thumb">
                              <div className="d-block position-relative overflow-hidden">
                                <Video
                                  src={`${process.env.REACT_APP_API_KEY}/${
                                    item.img.split(" ")[1]
                                  }`}
                                  res={true}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="post-thumb">
                              <div className="d-block position-relative overflow-hidden">
                                <img
                                  src={`${process.env.REACT_APP_API_KEY}/${item.img}`}
                                  className="img-fluid"
                                  alt="item.title"
                                />
                              </div>
                            </div>
                          )}

                          <div className="post-content">
                            <div className="entry-header hv">
                              <h3>{item?.title}</h3>
                            </div>
                          </div>
                        </article>
                      ) : null}
                      {/* Start ModalOneBlogContent */}
                      <Modal
                        isOpen={isOpen}
                        onRequestClose={() => setIsOpen(false)}
                        contentLabel="My dialog"
                        className="custom-modal green"
                        overlayClassName="custom-overlay light"
                        closeTimeoutMS={500}
                      >
                        <div>
                          <button
                            className="close-modal"
                            onClick={() => setIsOpen(false)}
                          >
                            <img src={cancelImg} alt="close icon" />
                          </button>
                          {/* End close icon */}

                          <div className="box_inner blog-post">
                            {/* Article Starts */}
                            <article>
                              <div className="title-section text-left text-sm-center">
                                <h1>
                                  Подробности <br />
                                  <span>события</span>
                                </h1>
                                <span className="title-bg">новости</span>
                              </div>
                              {/* Meta Starts */}

                              {isOpen ? (
                                <>
                                  {singleData.img.split(" ").length > 2 ? (
                                    <>
                                      <Video
                                        src={`${
                                          process.env.REACT_APP_API_KEY
                                        }/${singleData.img.split(" ")[1]}`}
                                      />
                                      <h3 className="heading mb-4 mt-4">
                                        {singleData?.title}
                                      </h3>
                                      <div className="meta open-sans-font">
                                        <span>
                                          <i className="fa fa-user-circle-o"></i>{" "}
                                          {singleData.company}
                                        </span>
                                        <span className="date">
                                          <i className="fa fa-calendar"></i>{" "}
                                          {moment(singleData.createdAt).format(
                                            "LL"
                                          )}
                                        </span>
                                        <span>
                                          <i className="fa fa-tags"></i>{" "}
                                          {singleData.type}
                                        </span>
                                      </div>
                                      <div className="blog-excerpt open-sans-font mb10">
                                        {/* <p>{singleData?.content}</p> */}
                                        <HtmlParser
                                          style={{ marginTop: "0px" }}
                                          html={singleData?.content}
                                        />
                                        <div>
                                          {singleData?.imgdesk1 ? (
                                            <TextBlock
                                              text={singleData?.desk1}
                                              img={singleData?.imgdesk1}
                                              top={singleData?.twoicons1}
                                              bot={singleData?.twoicons1bot}
                                            />
                                          ) : (
                                            <HtmlParser
                                              style={{ marginTop: "0px" }}
                                              html={singleData?.desk1}
                                            />
                                          )}
                                          {singleData?.imgdesk2 ? (
                                            <TextBlock
                                              text={singleData?.desk2}
                                              img={singleData?.imgdesk2}
                                              top={singleData?.twoicons2}
                                              bot={singleData?.twoicons2bot}
                                            />
                                          ) : (
                                            <HtmlParser
                                              style={{ marginTop: "0px" }}
                                              html={singleData?.desk2}
                                            />
                                          )}
                                          {singleData?.imgdesk3 ? (
                                            <TextBlock
                                              text={singleData?.desk3}
                                              img={singleData?.imgdesk3}
                                              top={singleData?.twoicons3}
                                              bot={singleData?.twoicons3bot}
                                            />
                                          ) : (
                                            <HtmlParser
                                              style={{ marginTop: "0px" }}
                                              html={singleData?.desk3}
                                            />
                                          )}
                                          {singleData?.imgdesk4 ? (
                                            <TextBlock
                                              text={singleData?.desk4}
                                              img={singleData?.imgdesk4}
                                              top={singleData?.twoicons4}
                                              bot={singleData?.twoicons4bot}
                                            />
                                          ) : (
                                            <HtmlParser
                                              style={{ marginTop: "0px" }}
                                              html={singleData?.desk4}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="imgaList">
                                        <Video
                                          src={`${
                                            process.env.REACT_APP_API_KEY
                                          }/${singleData.img.split(" ")[2]}`}
                                        />

                                        {singleData.img.split(" ")[3] && (
                                          <Video
                                            src={`${
                                              process.env.REACT_APP_API_KEY
                                            }/${singleData.img.split(" ")[3]}`}
                                          />
                                        )}
                                        {singleData.img.split(" ")[4] && (
                                          <Video
                                            src={`${
                                              process.env.REACT_APP_API_KEY
                                            }/${singleData.img.split(" ")[3]}`}
                                          />
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <Video
                                        src={`${
                                          process.env.REACT_APP_API_KEY
                                        }/${singleData.img.split(" ")[1]}`}
                                      />
                                      <div className="blog-excerpt open-sans-font pb-5">
                                        {/* <p>{singleData?.content}</p> */}

                                        <HtmlParser
                                          html={singleData?.content}
                                        />
                                        <div>
                                          {singleData?.imgdesk1 ? (
                                            <div className="quotebox">
                                              <div className="icon">
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_API_KEY +
                                                    "/" +
                                                    singleData?.imgdesk1
                                                  }
                                                  alt="blog quote"
                                                />
                                              </div>
                                              <HtmlParser
                                                style={{ marginTop: "0px" }}
                                                html={singleData?.desk1}
                                              />
                                              {singleData?.twoicons1 ? (
                                                <div className="icon2">
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_API_KEY +
                                                      "/" +
                                                      singleData?.imgdesk1
                                                    }
                                                    alt="blog quote"
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          ) : (
                                            <HtmlParser
                                              style={{ marginTop: "0px" }}
                                              html={singleData?.desk1}
                                            />
                                          )}
                                          {singleData?.imgdesk2 ? (
                                            <div className="quotebox">
                                              <div className="icon">
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_API_KEY +
                                                    "/" +
                                                    singleData?.imgdesk2
                                                  }
                                                  alt="blog quote"
                                                />
                                              </div>{" "}
                                              <HtmlParser
                                                style={{ marginTop: "0px" }}
                                                html={singleData?.desk2}
                                              />
                                              {singleData?.twoicons2 ? (
                                                <div className="icon2">
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_API_KEY +
                                                      "/" +
                                                      singleData?.imgdesk2
                                                    }
                                                    alt="blog quote"
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          ) : (
                                            <HtmlParser
                                              style={{ marginTop: "0px" }}
                                              html={singleData?.desk2}
                                            />
                                          )}
                                          {singleData?.imgdesk3 ? (
                                            <div className="quotebox">
                                              <div className="icon">
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_API_KEY +
                                                    "/" +
                                                    singleData?.imgdesk3
                                                  }
                                                  alt="blog quote"
                                                />
                                              </div>{" "}
                                              <HtmlParser
                                                style={{ marginTop: "0px" }}
                                                html={singleData?.desk3}
                                              />
                                              {singleData?.twoicons3 ? (
                                                <div className="icon2">
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_API_KEY +
                                                      "/" +
                                                      singleData?.imgdesk3
                                                    }
                                                    alt="blog quote"
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          ) : (
                                            <HtmlParser
                                              style={{ marginTop: "0px" }}
                                              html={singleData?.desk3}
                                            />
                                          )}
                                          {singleData?.imgdesk4 ? (
                                            <div className="quotebox">
                                              <div className="icon">
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_API_KEY +
                                                    "/" +
                                                    singleData?.imgdesk4
                                                  }
                                                  alt="blog quote"
                                                />
                                              </div>{" "}
                                              <HtmlParser
                                                style={{ marginTop: "0px" }}
                                                html={singleData?.desk4}
                                              />
                                              {singleData?.twoicons4 ? (
                                                <div className="icon2">
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_API_KEY +
                                                      "/" +
                                                      singleData?.imgdesk4
                                                    }
                                                    alt="blog quote"
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          ) : (
                                            <HtmlParser
                                              style={{ marginTop: "0px" }}
                                              html={singleData?.desk4}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : null}

                              {/* Article Content Ends */}
                            </article>
                            {/* Article Ends */}
                          </div>
                        </div>
                      </Modal>
                      {/* End  ModalOneBlogContent */}
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};

export default BlogLight;
