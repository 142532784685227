import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const experienceContent = [
  {
    year: "2016 - 2022",
    position: "Эксплуатация",
    compnayName: "решение по самострою",
    details: `Росимущество, Прокуратура, ДГИ и прочие органы Российской власти пытаются решить вопрос с самостроем в виде трех зданий (гостиница, фитнес и бизнес-центр), чтобы урегулировать статус земельного участка и наконец-то передать его жителям, чтобы жители смогли поставить шлакбаумы, назначить УК и прочее.`,
    slider: true,
	imgList: ["img/marshal2022-1.jpg", "img/marshal2022-2.jpg", "img/marshal2022-3.jpg"],
  },
  {
    year: "2013 - 2016",
    position: "Застройка",
    compnayName: "ФОК",
    slider: true,
    imgList: ["img/marshal2017-1.jpg", "img/marshal2017-2.jpg", "img/marshal2017-3.jpg", "img/marshal2017-4.jpg"],
    details: `Постройка корпусов ФОК (самострой)`,
  },
  {
    year: "2011 - 2013",
    position: "Застройка",
    compnayName: "Корпуса 3-9",
    slider: true,
    imgList: ["img/marshal2011-1.jpg", "img/marshal2011-2.jpg", "img/marshal2011-3.jpg", "img/marshal2011-4.jpg", "img/marshal2011-5.jpg", "img/marshal2011-6.jpg"],
    details: `Постройка коммерческих площадей вне госконтракта`,
  },
  {
    year: "2009 - 2011",
    position: "Застройка",
    compnayName: "Корпуса 1-2",
    slider: true,
    imgList: ["img/marshal2009-1.jpg", "img/marshal2009-2.jpg", "img/marshal2009-3.jpg", "img/marshal2009-4.jpg"],
    details: `Постройка корпусов для военных по цене 20000 ₽ за 1 кв.м`,
  },
  {
    year: "2008 - 2009",
    position: "Демонтаж",
    compnayName: "22 БТРЗ",
    slider: true,
    imgList: ["img/marshal2007-1.jpg", "img/marshal2007-2.jpg"],
    details: `Демонтаж 22 БТРЗ по контракту за счет 494 УНР, но Ким за взятки (50% от 2 млрд ₽) переложил это на бюджет Минобороны`,
  },
];

const Experience = () => {
    var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
  };
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-calendar"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
		  <p className="open-sans-font mb-3">{val.details}</p>
		  {val.slider && (
            <figure className="modal__img">
              <Slider {...settings}>
                {val.imgList.map((i) => (
                  <div className="sliderCard">
                    <img src={i} alt="portfolio project demo" />
                  </div>
                ))}
              </Slider>
            </figure>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Experience;
