import React, { useEffect, useState } from "react";
import CloseImg from "../../../assets/img/cancel.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./modal.css";
import Video from "../../Video";
import HtmlParser from "../../HtmlParser";
import cancelImg from "../../../assets/img/cancel.svg";
const Modals = ({ item, setModalActive }) => {
  console.log(item.type.split(",").length);
  const tags = (item) => {
    let a = item.type.split(",");
    let t = a.map((i, index) => {
      if (a.length - 1 === index) {
        return <span>{i}</span>;
      } else {
        return <span>{i + ", "}</span>;
      }
    });
    return t;
  };
  console.log(tags(item));
  return (
    <div className="box_inner blog-post pb30px">
      <article>
	  <div className="title-section text-left text-sm-center">
        	<h1>
            Подробности <br /><span>объявления</span>
            </h1>
            <span className="title-bg">Реклама</span>
        </div>
        <div className="modal__content">
          <h3 className="heading mb-2">{item.title}</h3>
          <div className="modal__details">
            <div className="col-12 col-sm-12 mb-4">
              {/* {" "} */}
              {/* <span className="ft-wt-600 uppercase"></span> */}
              <HtmlParser html={item.desc} />
            </div>
            <div className="row open-sans-font mb-4">
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-tags pr-2"></i>
                Тип:{" "}
                <span className="ft-wt-600 uppercase">
                  {tags(item).map((i, index) => {
                    return i;
                  })}
                </span>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-user-circle-o pr-2"></i>
                Контакт:{" "}
                <span className="ft-wt-600 uppercase">{item.executor}</span>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-calendar pr-2"></i>
                Дата: <span className="ft-wt-600 uppercase">{item.date}</span>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa hv fa-link pr-2"></i>
                Ссылка:{" "}
                <a
                  className="preview-link"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={item.link}
                >
                  {item.linkname}
                </a>
              </div>
            </div>
          </div>
          <div className="imgaList">
            {item.img.split(" ").map((elem, index) => {
              if (index) {
                return (
                  <div key={index} className="modal__img">
                    <Video src={`${process.env.REACT_APP_API_KEY}/${elem}`} />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </article>
    </div>
  );
};

export default Modals;
