import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import "./AdminPanel.css";
import { useSelector, useDispatch } from "react-redux";
import Tags from "./Tags";
import ReactSelect from "react-select";
import colourStyles from "./ColorStyles";
import { modulesQuil } from "./formatsQuill";
import { setOpen } from "../../store/pageSlice";
import Video from "../Video";
import UploadFile from "../UploadFile/UploadFile";
import HtmlParser from "../HtmlParser";
import ReactQuill from "react-quill";
import { DeleteOutlined } from "@ant-design/icons";
import UploadFileAdmin from "../UploadFile/UploadFileAdmin";
const Doc = () => {
  const dispath = useDispatch();
  const curentId = useSelector((state) => state.id.value);
  const { register, handleSubmit, control } = useForm();
  const navigate = useNavigate();
  const [fetched, setFetched] = useState(false);
  const [date, setDate] = useState();
  const params = useParams();
  const [doc, setDoc] = useState({});
  const [edit, setEdit] = useState(true);
  const [img, setImg] = useState(false);
  const [img2, setImg2] = useState(false);
  const [value, setValue] = useState("");

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  if (!localStorage.getItem("token")) {
    navigate("/admin");
  }

  useEffect(() => {
    const request = async () => {
      const responce = await axios.get(
        `${process.env.REACT_APP_API_KEY}/doc/${curentId}`
      );
      if (responce.status === 200 && responce.data) {
        setDoc(responce.data);
        setFetched(true);

        setValue(responce.data.desc);
        setImg2(responce.data.img);
      } else {
        navigate("/admin-panel");
      }
    };
    request();
  }, [curentId]);

  const deletePost = async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_KEY}/doc/delete/${curentId}`,
      config
    );
    doc.img.split(" ").map((i) => {
      axios.post(`${process.env.REACT_APP_API_KEY}/api/upload/remove`, {
        file: i,
      });
    });
    dispath(setOpen(false));
    navigate("/admin-panel");
  };

  const onSubmit = async (data) => {
    console.log(doc);
    let files = "";
    if (img) {
      data.img = img;
    } else {
      data.img = doc.img;
    }
    data.desc = value;
    if (img) {
      data.img.map((elem) => (files = files + " " + elem.response));
      data.img = files;
    } else {
      data.img = img2;
    }

    await axios.put(
      `${process.env.REACT_APP_API_KEY}/doc/edit/${curentId}`,
      data,
      config
    );

    dispath(setOpen(false));
    navigate("/admin-panel");
  };

  return (
    <>
      <div className="createApost box_inner blog-post">
        <div className="admin-panel">
          {fetched ? (
            <>
              {edit ? (
                <form className="admin-panel" onSubmit={handleSubmit(onSubmit)}>
                  <h1 className="blue">Редактирование документа</h1>

                  <input
                    className="admin-panel__input"
                    placeholder="Название"
                    defaultValue={doc.title}
                    {...register("title", { required: true })}
                  />
                  {/* <input
                    className="admin-panel__input"
                    placeholder="Описание"
                    defaultValue={doc.desc}
                    {...register("desc", { required: true })}
                  /> */}

                  <ReactQuill
                    modules={modulesQuil}
                    theme="snow"
                    className="mb20"
                    value={value}
                    onChange={setValue}
                  />
                  <input
                    className="admin-panel__input"
                    placeholder="Тип"
                    defaultValue={doc.type}
                    {...register("type", { required: true })}
                  />
                  <input
                    className="admin-panel__input"
                    defaultValue={doc.date}
                    placeholder="Дата"
                    {...register("date", { required: true })}
                  />
                  <input
                    className="admin-panel__input"
                    placeholder="Исполнитель"
                    defaultValue={doc.executor}
                    {...register("executor", { required: true })}
                  />
                  <input
                    className="admin-panel__input"
                    placeholder="Название ссылки"
                    defaultValue={doc.linkname}
                    {...register("linkname", { required: true })}
                  />
                  <input
                    className="admin-panel__input"
                    placeholder="Ссылка"
                    defaultValue={doc.link}
                    {...register("link", { required: true })}
                  />
                  {/* <UploadFile files={setImg} defaultFileList={doc.img} /> */}
                  <div className="form-group admupl">
                    <UploadFileAdmin defaultFileList={doc.img} files={setImg} />
                  </div>
                  <button type="submit" class="button ssd admbtns">
                    <span class="button-text" style={{ color: "#666" }}>
                      Отправить
                    </span>
                    <span class="button-icon fa fa-comment-o"></span>
                  </button>
                </form>
              ) : (
                <div className="adDesk">
                  <p className="blue">Название: </p>
                  <span>{doc.title}</span>
                  <div className="gr-3">
                    <p className="blue">Описание: </p>
                    {/* <span>{doc.desc}</span>*/}
                    <HtmlParser html={doc.desc} />
                  </div>
                  <p className="blue">Тип: </p>
                  <Tags doc={doc.type} />
                  <p className="blue">Дата: </p>
                  <span>{doc.date}</span>
                  <p className="blue">Исполнитель: </p>
                  <span>{doc.executor}</span>
                  <p className="blue">Название ссылки: </p>
                  <span>{doc.linkname}</span>
                  <p className="blue">Ссылка: </p>
                  <span>{doc.link}</span>

                  <div className="pimgAdmin">
                    <p className="blue"></p>
                    <div className="pimgAdmin_img">
                      {doc.img.split(" ").length > 2 ? (
                        doc.img.split(" ").map((elem, index) => {
                          if (index) {
                            return (
                              <figure key={index} className="modal__img">
                                <Video
                                  src={`${process.env.REACT_APP_API_KEY}/${elem}`}
                                />
                              </figure>
                            );
                          }
                        })
                      ) : (
                        <Video
                          src={`${process.env.REACT_APP_API_KEY}/${
                            doc.img.split(" ")[1]
                          }`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="editbtn">
                <button className="removepst" onClick={() => deletePost()}>
                  <DeleteOutlined />
                </button>
                {/* {edit ? (
                  <>
                    <button
                      className="subbtn"
                      onClick={() => dispath(setOpen(false))}
                    >
                      Отменить
                    </button>
                  </>
                ) : (
                  <button onClick={() => setEdit(true)} className="subbtn">
                    Редактировать
                  </button>
                )} */}
              </div>
            </>
          ) : (
            "Загрузка..."
          )}
        </div>
      </div>
    </>
  );
};

export default Doc;
