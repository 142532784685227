export const modulesQuil = {
  toolbar: [
    ["bold", "italic", "underline"],
    ["link"],
    ["clean"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
  ],
};
