import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-camera position-absolute"></i>
        <span className="d-block">События</span>Фото и видео интересных событий
      </p>
	  <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-building position-absolute"></i>
        <span className="d-block">Недвижимость</span>Аренда/продажа квартир и паркинга
      </p><p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-cutlery position-absolute"></i>
        <span className="d-block">Услуги</span>Реклама своих услуг
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;