import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import "./AdminPanel.css";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../store/pageSlice";
import UploadFile from "../UploadFile/UploadFile";
import ReactQuill from "react-quill";
import { modulesQuil } from "./formatsQuill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import DatePickerPost from "./DatePickerPost/DatePickerPost";
const CreatePost = () => {
  const dispath = useDispatch();
  const state = useSelector((state) => state.id.isOpen);
  const [value, setValue] = useState("");
  const [desk1, setDesk1] = useState("");
  const [desk2, setDesk2] = useState("");
  const [desk3, setDesk3] = useState("");
  const [desk4, setDesk4] = useState("");
  const navigate = useNavigate();
  const [img, setImg] = useState(null);
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  const [img3, setImg3] = useState(null);
  const [img4, setImg4] = useState(null);
  const [fileName, setFileName] = useState("");
  const [dateForm, setDateForm] = useState(moment().format());
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  const onSubmit = async (data) => {
    let files = "";
    data.content = value;
    data.desk1 = desk1;
    data.desk2 = desk2;
    data.desk3 = desk3;
    data.desk4 = desk4;

    img.map((elem) => (files = files + " " + elem.response));
    if (img1) {
      data.imgdesk1 = img1[0].response;
    }
    if (img2) {
      data.imgdesk2 = img2[0].response;
    }
    if (img3) {
      data.imgdesk3 = img3[0].response;
    }
    if (img4) {
      data.imgdesk4 = img4[0].response;
    }
    data.createdAt = dateForm;
    data.img = files;

    // console.log(img, img1, img2, img3, img4);
    console.log(data);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_KEY}/post/create`,
        data,
        config
      );
      dispath(setOpen(false));
    } catch (error) {
      alert(error);
      dispath(setOpen(false));
    }
  };
  return (
    <div className="box_inner blog-post createApost ">
      {localStorage.getItem("token") ? (
        <form className="admin-panel" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="blue">Добавить новость</h1>
          <div className={errors.title ? "err dv" : "dv"}>
            <input
              className="admin-panel__input"
              placeholder="Заголовок"
              {...register("title", { required: true })}
            />
          </div>{" "}
          <div className={errors.type ? "err dv" : "dv"}>
            <input
              className="admin-panel__input"
              placeholder="Тип"
              {...register("type", { required: true })}
            />
          </div>
          <div className={errors.createdAt ? "err dv" : "dv"}>
            {/* <input
              className="admin-panel__input"
              placeholder="Дата"
              {...register("createdAt", { required: true })}
            /> */}
            <DatePickerPost formvalue={setDateForm} />
          </div>
          <div className={errors.company ? "err dv" : "dv"}>
            <input
              className="admin-panel__input"
              placeholder="Источник"
              {...register("company", { required: true })}
            />
          </div>
          {/* <div className={errors.content ? "err dv" : "dv "}>
            <ReactQuill
              theme="snow"
              className="mb20"
              value={value}
              onChange={setValue}twoicons1
            />
          </div> */}
          <ReactQuill
            modules={modulesQuil}
            theme="snow"
            className="mb20"
            value={desk1}
            onChange={setDesk1}
          />
          <div className="uploadtwoicons">
            <UploadFile
              files={setImg1}
              multiple={false}
              imgLink={"https://89.108.83.230/"}
            />
            <div className="dflexmob">
              <label className="twoIcons">
                <input
                  className="admin-panel__input"
                  placeholder="Дата"
                  type={"checkbox"}
                  {...register("twoicons1", { required: false })}
                />
              </label>
              <label className="twoIcons">
                <input
                  className="admin-panel__input"
                  placeholder="Дата"
                  type={"checkbox"}
                  {...register("twoicons1bot", { required: false })}
                />
              </label>
            </div>
          </div>
          <ReactQuill
            modules={modulesQuil}
            theme="snow"
            className="mb20"
            value={desk2}
            onChange={setDesk2}
          />
          <div className="uploadtwoicons">
            {" "}
            <UploadFile
              files={setImg2}
              multiple={false}
              imgLink={"https://89.108.83.230/"}
            />
            <div className="dflexmob">
              <label className="twoIcons">
                <input
                  className="admin-panel__input"
                  placeholder="Дата"
                  type={"checkbox"}
                  {...register("twoicons2", { required: false })}
                />
              </label>
              <label className="twoIcons">
                <input
                  className="admin-panel__input"
                  placeholder="Дата"
                  type={"checkbox"}
                  {...register("twoicons2bot", { required: false })}
                />
              </label>
            </div>
          </div>
          <ReactQuill
            modules={modulesQuil}
            theme="snow"
            className="mb20"
            value={desk3}
            onChange={setDesk3}
          />{" "}
          <div className="uploadtwoicons">
            <UploadFile
              files={setImg3}
              multiple={false}
              imgLink={"https://89.108.83.230/"}
            />
            <div className="dflexmob">
              <label className="twoIcons">
                <input
                  className="admin-panel__input"
                  placeholder="Дата"
                  type={"checkbox"}
                  {...register("twoicons3", { required: false })}
                />
              </label>
              <label className="twoIcons">
                <input
                  className="admin-panel__input"
                  placeholder="Дата"
                  type={"checkbox"}
                  {...register("twoicons3bot", { required: false })}
                />
              </label>
            </div>
          </div>
          <ReactQuill
            modules={modulesQuil}
            theme="snow"
            className="mb20"
            value={desk4}
            onChange={setDesk4}
          />{" "}
          <div className="uploadtwoicons">
            {" "}
            <UploadFile
              files={setImg4}
              multiple={false}
              imgLink={"https://89.108.83.230/"}
            />
            <div className="dflexmob">
              <label className="twoIcons">
                <input
                  className="admin-panel__input"
                  placeholder="Дата"
                  type={"checkbox"}
                  {...register("twoicons4", { required: false })}
                />
              </label>
              <label className="twoIcons">
                <input
                  className="admin-panel__input"
                  placeholder="Дата"
                  type={"checkbox"}
                  {...register("twoicons4bot", { required: false })}
                />
              </label>
            </div>
          </div>
          <div className="form-group admupl">
            <UploadFile files={setImg} imgLink={"https://89.108.83.230/"} />
          </div>
          {/* <input type="submit" className="subbtn" /> */}
          <button
            type="submit"
            class="button ssd admbtns"
            style={{ marginTop: "20px" }}
          >
            <span class="button-text" style={{ color: "#666" }}>
              {" "}
              Отправить
            </span>
            <span class="button-icon fa fa-comment-o"></span>
          </button>
        </form>
      ) : (
        <Link to="/admin">Зайдите в аккаунт</Link>
      )}
    </div>
  );
};

export default CreatePost;
