import React, { useState, useCallback, forwardRef, useEffect } from "react";
import imgTop from "../../assets/img/marshal1.jpg";
import imgTop2 from "../../assets/img/marshal2.jpg";
import imgBottom from "../../assets/img/marshal3.jpg";
import { Image } from "antd";
import { wrapGrid } from "animate-css-grid";
import Achievements from "./Achievements";
import Education from "./Education";
import Experience from "./Experience";
import PersonalInfo from "./PersonalInfo";
import Skills from "./Skills";
import heroImgMobile from "../../assets/img/hero/hero.jpg";
import Modal from "react-modal";
import cancelImg from "../../assets/img/cancel.svg";
import Img from "./Img";
import FlipMove from "react-flip-move";
import style from "./Img.module.css";
import TestImgGalery from "./TestImgGalery";
import { useSelector, useDispatch } from "react-redux";
const Index = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setstate] = useState(false);

  const gl = useSelector((state) => state.id.galery);

  useEffect(() => {
    setstate(gl);
    console.log(state);
  }, [gl, state]);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  // document.querySelector(".fasd").addEventListener("scroll", function (e) {
  //   console.log(e);
  // });
  const articles = [
    { img: "https://loremflickr.com/1300/1300", id: 1 },
    { img: "https://loremflickr.com/1300/1300", id: 2 },
    { img: "https://loremflickr.com/1300/1300", id: 3 },
  ];
  const setClick = (e) => {
    console.log(e);
  };
  return (
    <section className="main-content">
      <div className="container">
        <div className="row">
          {/* Personal Info Starts */}

          <div className="col-xl-6 col-lg-5 col-12">
            <div className="row">
              <div className="col-12">
                <h3 className="text-uppercase custom-title mb-0 ft-wt-600">
                  ­План и факт
                </h3>
              </div>
              {/* End .col */}

              <div className="col-12 d-block d-sm-none">
                <img
                  src={heroImgMobile}
                  className="img-fluid main-img-mobile1"
                  alt="about avatar"
                />
              </div>
              {/* image for mobile menu */}

              <div className="col-12">
                <PersonalInfo />
              </div>
              {/* End personal info */}

              <div className="col-12 mt-1">
                {/* <a className="button" href={cv} download> */}
                {/* <span className="button-text">Скачать проект</span></a> */}
                <button className="button" onClick={toggleModalOne}>
                  <span className="button-text">Планировки</span>
                  <span className="button-icon fa fa-map-o"></span>
                </button>
                <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModalOne}
                  contentLabel="My dialog"
                  className="custom-modal dark light"
                  overlayClassName="custom-overlay"
                  closeTimeoutMS={500}
                >
                  <div className="galscrol">
                    <button className="close-modal" onClick={toggleModalOne}>
                      <img src={cancelImg} alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className={`box_inner blog-post pb30px `} id="fase">
                      <div data-aos="fade-up" data-aos-duration="1200">
                        <div className={state ? "wrpgl" : ""}>
                          <div className="title-section text-left text-sm-center mb-5">
                            <h1>
                              Планировки <br /><span>Маршала</span>
                            </h1>
                            <span className="title-bg1">Проект</span>
                          </div>
                          <TestImgGalery />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End modal box news */}
                </Modal>
              </div>
              {/* End download button */}
            </div>
          </div>
          {/*  Personal Info Ends */}

          {/*  Boxes Starts */}
          <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
            <Achievements />
          </div>
          {/* Achievements Ends */}
        </div>
        {/* End .row */}

        <hr className="separator" />

        {/* Skills Starts */}
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
              Сегодня
            </h3>
          </div>
          <Skills />
        </div>
        {/* Skills Ends */}

        <hr className="separator mt-1" />

        {/* Experience & Education Starts */}
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
              Хронология
            </h3>
          </div>
          <div className="col-lg-6 m-15px-tb">
            <div className="resume-box">
              <Experience />
            </div>
          </div>
          <div className="col-lg-6 m-15px-tb">
            <div className="resume-box">
              <Education />
            </div>
          </div>
        </div>
        {/*  Experience & Education Ends */}
      </div>
    </section>
  );
};

export default Index;
