import React from "react";
import img1 from "../../assets/img/about/marshal_galary_001.jpg";
import img2 from "../../assets/img/about/marshal_galary_002.jpg";
import img3 from "../../assets/img/about/marshal_galary_003.jpg";
import img4 from "../../assets/img/about/marshal_galary_004.jpg";
import img5 from "../../assets/img/about/marshal_galary_005.jpg";
import img6 from "../../assets/img/about/marshal_galary_006.jpg";
import img7 from "../../assets/img/about/marshal_galary_007.jpg";
import img8 from "../../assets/img/about/marshal_galary_008.jpg";
import img9 from "../../assets/img/about/marshal_galary_009.jpg";
import img10 from "../../assets/img/about/marshal_galary_010.jpg";
import img11 from "../../assets/img/about/marshal_galary_011.jpg";
import img12 from "../../assets/img/about/marshal_galary_012.jpg";
import img13 from "../../assets/img/about/marshal_galary_013.jpg";
import img14 from "../../assets/img/about/marshal_galary_014.jpg";
import img15 from "../../assets/img/about/marshal_galary_015.jpg";
import img16 from "../../assets/img/about/marshal_galary_016.jpg";
import img17 from "../../assets/img/about/marshal_galary_017.jpg";
import img18 from "../../assets/img/about/marshal_galary_018.jpg";
import img19 from "../../assets/img/about/marshal_galary_019.jpg";
import img20 from "../../assets/img/about/marshal_galary_020.jpg";
import img21 from "../../assets/img/about/marshal_galary_021.jpg";
import img22 from "../../assets/img/about/marshal_galary_022.jpg";
import img23 from "../../assets/img/about/marshal_galary_023.jpg";
import img24 from "../../assets/img/about/marshal_galary_024.jpg";
import img25 from "../../assets/img/about/marshal_galary_025.jpg";
import img26 from "../../assets/img/about/marshal_galary_026.jpg";
import img27 from "../../assets/img/about/marshal_galary_027.jpg";
import img28 from "../../assets/img/about/marshal_galary_028.jpg";
import img29 from "../../assets/img/about/marshal_galary_029.jpg";
import img30 from "../../assets/img/about/marshal_galary_030.jpg";
import img31 from "../../assets/img/about/marshal_galary_031.jpg";
import img32 from "../../assets/img/about/marshal_galary_032.jpg";
import img33 from "../../assets/img/about/marshal_galary_033.jpg";
import img34 from "../../assets/img/about/marshal_galary_034.jpg";
import img35 from "../../assets/img/about/marshal_galary_035.jpg";
import img36 from "../../assets/img/about/marshal_galary_036.jpg";
import img37 from "../../assets/img/about/marshal_galary_037.jpg";
import img38 from "../../assets/img/about/marshal_galary_038.jpg";
import img39 from "../../assets/img/about/marshal_galary_039.jpg";
import img40 from "../../assets/img/about/marshal_galary_040.jpg";
import img41 from "../../assets/img/about/marshal_galary_041.jpg";
import img42 from "../../assets/img/about/marshal_galary_042.jpg";
import img43 from "../../assets/img/about/marshal_galary_043.jpg";
import img44 from "../../assets/img/about/marshal_galary_044.jpg";
import img45 from "../../assets/img/about/marshal_galary_045.jpg";
import img46 from "../../assets/img/about/marshal_galary_046.jpg";
import img47 from "../../assets/img/about/marshal_galary_047.jpg";
import img48 from "../../assets/img/about/marshal_galary_048.jpg";
import img49 from "../../assets/img/about/marshal_galary_049.jpg";
import img50 from "../../assets/img/about/marshal_galary_050.jpg";
import img51 from "../../assets/img/about/marshal_galary_051.jpg";
import img52 from "../../assets/img/about/marshal_galary_052.jpg";
import img53 from "../../assets/img/about/marshal_galary_053.jpg";
import img54 from "../../assets/img/about/marshal_galary_054.jpg";
import img55 from "../../assets/img/about/marshal_galary_055.jpg";
import img56 from "../../assets/img/about/marshal_galary_056.jpg";
import img57 from "../../assets/img/about/marshal_galary_057.jpg";
import img58 from "../../assets/img/about/marshal_galary_058.jpg";
import img59 from "../../assets/img/about/marshal_galary_059.jpg";
import img60 from "../../assets/img/about/marshal_galary_060.jpg";
import img61 from "../../assets/img/about/marshal_galary_061.jpg";
import img62 from "../../assets/img/about/marshal_galary_062.jpg";
import img63 from "../../assets/img/about/marshal_galary_063.jpg";
import img64 from "../../assets/img/about/marshal_galary_064.jpg";
import img65 from "../../assets/img/about/marshal_galary_065.jpg";
import img66 from "../../assets/img/about/marshal_galary_066.jpg";
import img67 from "../../assets/img/about/marshal_galary_067.jpg";
import img68 from "../../assets/img/about/marshal_galary_068.jpg";
import img69 from "../../assets/img/about/marshal_galary_069.jpg";
import img70 from "../../assets/img/about/marshal_galary_070.jpg";
import img71 from "../../assets/img/about/marshal_galary_071.jpg";
import img72 from "../../assets/img/about/marshal_galary_072.jpg";
import img73 from "../../assets/img/about/marshal_galary_073.jpg";
import img74 from "../../assets/img/about/marshal_galary_074.jpg";
import img75 from "../../assets/img/about/marshal_galary_075.jpg";
import img76 from "../../assets/img/about/marshal_galary_076.jpg";
import img77 from "../../assets/img/about/marshal_galary_077.jpg";
import img78 from "../../assets/img/about/marshal_galary_078.jpg";
import img79 from "../../assets/img/about/marshal_galary_079.jpg";
import img80 from "../../assets/img/about/marshal_galary_080.jpg";
import img81 from "../../assets/img/about/marshal_galary_081.jpg";
import img82 from "../../assets/img/about/marshal_galary_082.jpg";
import img83 from "../../assets/img/about/marshal_galary_083.jpg";
import img84 from "../../assets/img/about/marshal_galary_084.jpg";
import img85 from "../../assets/img/about/marshal_galary_085.jpg";
import img86 from "../../assets/img/about/marshal_galary_086.jpg";
import img87 from "../../assets/img/about/marshal_galary_087.jpg";
import img88 from "../../assets/img/about/marshal_galary_088.jpg";
import img89 from "../../assets/img/about/marshal_galary_089.jpg";
import img90 from "../../assets/img/about/marshal_galary_090.jpg";
import img91 from "../../assets/img/about/marshal_galary_091.jpg";
import img92 from "../../assets/img/about/marshal_galary_092.jpg";
import img93 from "../../assets/img/about/marshal_galary_093.jpg";
import img94 from "../../assets/img/about/marshal_galary_094.jpg";
import img95 from "../../assets/img/about/marshal_galary_095.jpg";
import img96 from "../../assets/img/about/marshal_galary_096.jpg";
import img97 from "../../assets/img/about/marshal_galary_097.jpg";
import img98 from "../../assets/img/about/marshal_galary_098.jpg";
import img99 from "../../assets/img/about/marshal_galary_099.jpg";
import img100 from "../../assets/img/about/marshal_galary_100.jpg";
import img101 from "../../assets/img/about/marshal_galary_101.jpg";
import img102 from "../../assets/img/about/marshal_galary_102.jpg";
import img103 from "../../assets/img/about/marshal_galary_103.jpg";
import img104 from "../../assets/img/about/marshal_galary_104.jpg";
import img105 from "../../assets/img/about/marshal_galary_105.jpg";
import img106 from "../../assets/img/about/marshal_galary_106.jpg";
import img107 from "../../assets/img/about/marshal_galary_107.jpg";
import img108 from "../../assets/img/about/marshal_galary_108.jpg";
import img109 from "../../assets/img/about/marshal_galary_109.jpg";
import img110 from "../../assets/img/about/marshal_galary_110.jpg";
import img111 from "../../assets/img/about/marshal_galary_111.jpg";
import img112 from "../../assets/img/about/marshal_galary_112.jpg";
import img113 from "../../assets/img/about/marshal_galary_113.jpg";
import img114 from "../../assets/img/about/marshal_galary_114.jpg";
import img115 from "../../assets/img/about/marshal_galary_115.jpg";
import img116 from "../../assets/img/about/marshal_galary_116.jpg";
import img117 from "../../assets/img/about/marshal_galary_117.jpg";
import img118 from "../../assets/img/about/marshal_galary_118.jpg";
import img119 from "../../assets/img/about/marshal_galary_119.jpg";
import img120 from "../../assets/img/about/marshal_galary_120.jpg";
import img121 from "../../assets/img/about/marshal_galary_121.jpg";
import img122 from "../../assets/img/about/marshal_galary_122.jpg";
import img123 from "../../assets/img/about/marshal_galary_123.jpg";
import img124 from "../../assets/img/about/marshal_galary_124.jpg";
import img125 from "../../assets/img/about/marshal_galary_125.jpg";
import img126 from "../../assets/img/about/marshal_galary_126.jpg";
import img127 from "../../assets/img/about/marshal_galary_127.jpg";
import img128 from "../../assets/img/about/marshal_galary_128.jpg";
import img129 from "../../assets/img/about/marshal_galary_129.jpg";
import img130 from "../../assets/img/about/marshal_galary_130.jpg";
import img131 from "../../assets/img/about/marshal_galary_131.jpg";
import img132 from "../../assets/img/about/marshal_galary_132.jpg";
import img133 from "../../assets/img/about/marshal_galary_133.jpg";
import img134 from "../../assets/img/about/marshal_galary_134.jpg";
import img135 from "../../assets/img/about/marshal_galary_135.jpg";
import img136 from "../../assets/img/about/marshal_galary_136.jpg";
import img137 from "../../assets/img/about/marshal_galary_137.jpg";
import img138 from "../../assets/img/about/marshal_galary_138.jpg";
import img139 from "../../assets/img/about/marshal_galary_139.jpg";
import img140 from "../../assets/img/about/marshal_galary_140.jpg";
import img141 from "../../assets/img/about/marshal_galary_141.jpg";
import img142 from "../../assets/img/about/marshal_galary_142.jpg";
import img143 from "../../assets/img/about/marshal_galary_143.jpg";
import img144 from "../../assets/img/about/marshal_galary_144.jpg";
import img145 from "../../assets/img/about/marshal_galary_145.jpg";
import img146 from "../../assets/img/about/marshal_galary_146.jpg";
import img147 from "../../assets/img/about/marshal_galary_147.jpg";
import img148 from "../../assets/img/about/marshal_galary_148.jpg";
import img149 from "../../assets/img/about/marshal_galary_149.jpg";
import img150 from "../../assets/img/about/marshal_galary_150.jpg";
import img151 from "../../assets/img/about/marshal_galary_151.jpg";
import img152 from "../../assets/img/about/marshal_galary_152.jpg";
import img153 from "../../assets/img/about/marshal_galary_153.jpg";
import img154 from "../../assets/img/about/marshal_galary_154.jpg";
import img155 from "../../assets/img/about/marshal_galary_155.jpg";
import img156 from "../../assets/img/about/marshal_galary_156.jpg";
import img157 from "../../assets/img/about/marshal_galary_157.jpg";
import img158 from "../../assets/img/about/marshal_galary_158.jpg";
import img159 from "../../assets/img/about/marshal_galary_159.jpg";
import img160 from "../../assets/img/about/marshal_galary_160.jpg";
import img161 from "../../assets/img/about/marshal_galary_161.jpg";
import img162 from "../../assets/img/about/marshal_galary_162.jpg";
import img163 from "../../assets/img/about/marshal_galary_163.jpg";
import img164 from "../../assets/img/about/marshal_galary_164.jpg";
import img165 from "../../assets/img/about/marshal_galary_165.jpg";
import img166 from "../../assets/img/about/marshal_galary_166.jpg";
import img167 from "../../assets/img/about/marshal_galary_167.jpg";
import img168 from "../../assets/img/about/marshal_galary_168.jpg";
import img169 from "../../assets/img/about/marshal_galary_169.jpg";
import img170 from "../../assets/img/about/marshal_galary_170.jpg";
import img171 from "../../assets/img/about/marshal_galary_171.jpg";
import img172 from "../../assets/img/about/marshal_galary_172.jpg";
import img173 from "../../assets/img/about/marshal_galary_173.jpg";
import img174 from "../../assets/img/about/marshal_galary_174.jpg";
import img175 from "../../assets/img/about/marshal_galary_175.jpg";
import img176 from "../../assets/img/about/marshal_galary_176.jpg";
import img177 from "../../assets/img/about/marshal_galary_177.jpg";
import img178 from "../../assets/img/about/marshal_galary_178.jpg";
import img179 from "../../assets/img/about/marshal_galary_179.jpg";
import img180 from "../../assets/img/about/marshal_galary_180.jpg";
import img181 from "../../assets/img/about/marshal_galary_181.jpg";
import img182 from "../../assets/img/about/marshal_galary_182.jpg";
import img183 from "../../assets/img/about/marshal_galary_183.jpg";
import img184 from "../../assets/img/about/marshal_galary_184.jpg";
import img185 from "../../assets/img/about/marshal_galary_185.jpg";
import img186 from "../../assets/img/about/marshal_galary_186.jpg";
import img187 from "../../assets/img/about/marshal_galary_187.jpg";
import img188 from "../../assets/img/about/marshal_galary_188.jpg";
import img189 from "../../assets/img/about/marshal_galary_189.jpg";
import img190 from "../../assets/img/about/marshal_galary_190.jpg";
import img191 from "../../assets/img/about/marshal_galary_191.jpg";
import img192 from "../../assets/img/about/marshal_galary_192.jpg";
import img193 from "../../assets/img/about/marshal_galary_193.jpg";
import img194 from "../../assets/img/about/marshal_galary_194.jpg";
import img195 from "../../assets/img/about/marshal_galary_195.jpg";
import img196 from "../../assets/img/about/marshal_galary_196.jpg";
import img197 from "../../assets/img/about/marshal_galary_197.jpg";
import img198 from "../../assets/img/about/marshal_galary_198.jpg";
import img199 from "../../assets/img/about/marshal_galary_199.jpg";
import img200 from "../../assets/img/about/marshal_galary_200.jpg";
import img201 from "../../assets/img/about/marshal_galary_201.jpg";
import img202 from "../../assets/img/about/marshal_galary_202.jpg";
import img203 from "../../assets/img/about/marshal_galary_203.jpg";
import img204 from "../../assets/img/about/marshal_galary_204.jpg";
import img205 from "../../assets/img/about/marshal_galary_205.jpg";
import img206 from "../../assets/img/about/marshal_galary_206.jpg";
import img207 from "../../assets/img/about/marshal_galary_207.jpg";
import img208 from "../../assets/img/about/marshal_galary_208.jpg";
import img209 from "../../assets/img/about/marshal_galary_209.jpg";
import img210 from "../../assets/img/about/marshal_galary_210.jpg";
import img211 from "../../assets/img/about/marshal_galary_211.jpg";
import img212 from "../../assets/img/about/marshal_galary_212.jpg";
import img213 from "../../assets/img/about/marshal_galary_213.jpg";
import img214 from "../../assets/img/about/marshal_galary_214.jpg";
import img215 from "../../assets/img/about/marshal_galary_215.jpg";
import img216 from "../../assets/img/about/marshal_galary_216.jpg";
import img217 from "../../assets/img/about/marshal_galary_217.jpg";
import img218 from "../../assets/img/about/marshal_galary_218.jpg";
import img219 from "../../assets/img/about/marshal_galary_219.jpg";
import img220 from "../../assets/img/about/marshal_galary_220.jpg";
import img221 from "../../assets/img/about/marshal_galary_221.jpg";
import img222 from "../../assets/img/about/marshal_galary_222.jpg";
import img223 from "../../assets/img/about/marshal_galary_223.jpg";
import img224 from "../../assets/img/about/marshal_galary_224.jpg";
import img225 from "../../assets/img/about/marshal_galary_225.jpg";
import img226 from "../../assets/img/about/marshal_galary_226.jpg";
import img227 from "../../assets/img/about/marshal_galary_227.jpg";
import img228 from "../../assets/img/about/marshal_galary_228.jpg";
import img229 from "../../assets/img/about/marshal_galary_229.jpg";
import img230 from "../../assets/img/about/marshal_galary_230.jpg";
import img231 from "../../assets/img/about/marshal_galary_231.jpg";
import img232 from "../../assets/img/about/marshal_galary_232.jpg";
import img233 from "../../assets/img/about/marshal_galary_233.jpg";
import img234 from "../../assets/img/about/marshal_galary_234.jpg";
import img235 from "../../assets/img/about/marshal_galary_235.jpg";
import img236 from "../../assets/img/about/marshal_galary_236.jpg";
import img237 from "../../assets/img/about/marshal_galary_237.jpg";
import img238 from "../../assets/img/about/marshal_galary_238.jpg";
import img239 from "../../assets/img/about/marshal_galary_239.jpg";
import img240 from "../../assets/img/about/marshal_galary_240.jpg";
import img241 from "../../assets/img/about/marshal_galary_241.jpg";
import img242 from "../../assets/img/about/marshal_galary_242.jpg";
import img243 from "../../assets/img/about/marshal_galary_243.jpg";
import img244 from "../../assets/img/about/marshal_galary_244.jpg";
import img245 from "../../assets/img/about/marshal_galary_245.jpg";
import img246 from "../../assets/img/about/marshal_galary_246.jpg";
import img247 from "../../assets/img/about/marshal_galary_247.jpg";
import img248 from "../../assets/img/about/marshal_galary_248.jpg";
import img249 from "../../assets/img/about/marshal_galary_249.jpg";
import img250 from "../../assets/img/about/marshal_galary_250.jpg";
import img251 from "../../assets/img/about/marshal_galary_251.jpg";
import img252 from "../../assets/img/about/marshal_galary_252.jpg";
import img253 from "../../assets/img/about/marshal_galary_253.jpg";
import img254 from "../../assets/img/about/marshal_galary_254.jpg";
import img255 from "../../assets/img/about/marshal_galary_255.jpg";
import img256 from "../../assets/img/about/marshal_galary_256.jpg";
import img257 from "../../assets/img/about/marshal_galary_257.jpg";
import img258 from "../../assets/img/about/marshal_galary_258.jpg";
import img259 from "../../assets/img/about/marshal_galary_259.jpg";
import img260 from "../../assets/img/about/marshal_galary_260.jpg";
import img261 from "../../assets/img/about/marshal_galary_261.jpg";
import img262 from "../../assets/img/about/marshal_galary_262.jpg";
import img263 from "../../assets/img/about/marshal_galary_263.jpg";
import img264 from "../../assets/img/about/marshal_galary_264.jpg";
import img265 from "../../assets/img/about/marshal_galary_265.jpg";
import img266 from "../../assets/img/about/marshal_galary_266.jpg";
import img267 from "../../assets/img/about/marshal_galary_267.jpg";
import img268 from "../../assets/img/about/marshal_galary_268.jpg";
import img269 from "../../assets/img/about/marshal_galary_269.jpg";
import img270 from "../../assets/img/about/marshal_galary_270.jpg";
import img271 from "../../assets/img/about/marshal_galary_271.jpg";
import img272 from "../../assets/img/about/marshal_galary_272.jpg";
import img273 from "../../assets/img/about/marshal_galary_273.jpg";
import img274 from "../../assets/img/about/marshal_galary_274.jpg";
import img275 from "../../assets/img/about/marshal_galary_275.jpg";
import img276 from "../../assets/img/about/marshal_galary_276.jpg";
import img277 from "../../assets/img/about/marshal_galary_277.jpg";
import img278 from "../../assets/img/about/marshal_galary_278.jpg";
import img279 from "../../assets/img/about/marshal_galary_279.jpg";
import img280 from "../../assets/img/about/marshal_galary_280.jpg";
import img281 from "../../assets/img/about/marshal_galary_281.jpg";
import img282 from "../../assets/img/about/marshal_galary_282.jpg";
import img283 from "../../assets/img/about/marshal_galary_283.jpg";
import img284 from "../../assets/img/about/marshal_galary_284.jpg";
import img285 from "../../assets/img/about/marshal_galary_285.jpg";
import img286 from "../../assets/img/about/marshal_galary_286.jpg";
import img287 from "../../assets/img/about/marshal_galary_287.jpg";
import img288 from "../../assets/img/about/marshal_galary_288.jpg";
import img289 from "../../assets/img/about/marshal_galary_289.jpg";
import img290 from "../../assets/img/about/marshal_galary_290.jpg";
import img291 from "../../assets/img/about/marshal_galary_291.jpg";
import img292 from "../../assets/img/about/marshal_galary_292.jpg";
import img293 from "../../assets/img/about/marshal_galary_293.jpg";
import img294 from "../../assets/img/about/marshal_galary_294.jpg";
import img295 from "../../assets/img/about/marshal_galary_295.jpg";
import img296 from "../../assets/img/about/marshal_galary_296.jpg";
import img297 from "../../assets/img/about/marshal_galary_297.jpg";
import img298 from "../../assets/img/about/marshal_galary_298.jpg";

import { LazyLoadImage } from "react-lazy-load-image-component";
import quote from "../../assets/img/blog/quote.svg";
const index2 = () => {
  let imList = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img52,
    img53,
    img54,
    img55,
    img56,
    img57,
    img58,
    img59,
    img60,
    img61,
    img62,
    img63,
    img64,
    img65,
    img66,
    img67,
    img68,
    img69,
    img70,
    img71,
    img72,
    img73,
    img74,
    img75,
    img76,
    img77,
    img78,
    img79,
	img80,
    img81,
    img82,
    img83,
    img84,
    img85,
    img86,
    img87,
    img88,
    img89,
    img90,
    img91,
	img92,
    img93,
    img94,
    img95,
    img96,
    img97,
    img98,
    img99,
    img100,
	img101,
    img102,
    img103,
    img104,
    img105,
    img106,
    img107,
    img108,
    img109,
    img110,
    img111,
    img112,
    img113,
    img114,
    img115,
    img116,
    img117,
    img118,
    img119,
    img120,
    img121,
    img122,
    img123,
    img124,
    img125,
    img126,
    img127,
    img128,
    img129,
    img130,
    img131,
    img132,
    img133,
    img134,
    img135,
    img136,
    img137,
    img138,
    img139,
    img140,
    img141,
    img142,
    img143,
    img144,
    img145,
    img146,
    img147,
    img148,
    img149,
    img150,
    img151,
    img152,
    img153,
    img154,
    img155,
    img156,
    img157,
    img158,
    img159,
    img160,
    img161,
    img162,
    img163,
    img164,
    img165,
    img166,
    img167,
    img168,
    img169,
    img170,
    img171,
    img172,
    img173,
    img174,
    img175,
    img176,
    img177,
    img178,
    img179,
	img180,
    img181,
    img182,
    img183,
    img184,
    img185,
    img186,
    img187,
    img188,
    img189,
    img190,
    img191,
	img192,
    img193,
	img194,
    img195,
    img196,
    img197,
    img198,
    img199,
	img200,
    img201,
    img202,
    img203,
    img204,
    img205,
    img206,
	img207,
    img208,
    img209,
    img210,
    img211,
    img212,
    img213,
    img214,
    img215,
    img216,
    img217,
    img218,
    img219,
    img220,
    img221,
    img222,
    img223,
	img224,
    img225,
    img226,
	img227,
    img228,
    img229,
    img230,
    img231,
    img232,
    img233,
    img234,
    img235,
    img236,
    img237,
    img238,
    img239,
    img240,
    img241,
    img242,
    img243,
    img244,
    img245,
    img246,
    img247,
    img248,
    img249,
    img250,
    img251,
    img252,
    img253,
    img254,
	img255,
    img256,
    img257,
    img258,
    img259,
    img260,
    img261,
    img262,
    img263,
	img264,
    img265,
    img266,
    img267,
    img268,
    img269,
    img270,
    img271,
    img272,
    img273,
    img274,
    img275,
    img276,
    img277,
    img278,
    img279,
	img280,
    img281,
    img282,
    img283,
    img284,
    img285,
    img286,
    img287,
    img288,
    img289,
    img290,
    img291,
	img292,
    img293,
	img294,
    img295,
    img96,
    img297,
    img298,

  ];
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  shuffle(imList);
  return (
    <section className="main-content">
      <div className="imgaList">
        {imList.map((i) => {
          return (
            <LazyLoadImage
              key={i}
              effect="blur"
              style={{ width: "100%", borderRadius: "10px" }}
              src={i}
              className="img-fluid"
              alt="hero man"
            />
          );
        })}
      </div>
    </section>
  );
};

export default index2;
