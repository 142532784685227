import React from "react";
import img1 from "../../assets/img/about/cv_1.png";
import img2 from "../../assets/img/about/cv_2.png";
import img3 from "../../assets/img/about/cv_3.png";
import img4 from "../../assets/img/about/cv_4.png";
import img5 from "../../assets/img/about/cv_5.png";
import img6 from "../../assets/img/about/cv_6.png";
import img7 from "../../assets/img/about/cv_7.png";
import img8 from "../../assets/img/about/cv_8.png";
import img9 from "../../assets/img/about/cv_9.png";
import img10 from "../../assets/img/about/cv_10.png";

import quote from "../../assets/img/blog/quote.svg";

const index2 = () => {
  let img = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  shuffle(img);
  return (
    <section className="main-content">
      <div class="imgaList">
        {img.map((i) => {
          return (
            <img
              style={{ width: "100%", borderRadius: "10px" }}
              src={i}
              className="img-fluid"
              alt="hero man"
            />
          );
        })}
      </div>
    </section>
  );
};

export default index2;
