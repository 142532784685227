import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import update from "immutability-helper";
import React, { useCallback, useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragableUploadListItem from "./DragableUploadListItem";
import { PlusCircleOutlined } from "@ant-design/icons";
import Video from "../Video";
import IconRender from "./IconRender";
export default function UploadFileAdmin({
  files,
  link = "https://89.108.83.230/api/upload/",
  multiple = true,
  defaultFileList = "",
  title = "",
  removefiles,
}) {
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState([]);
  useEffect(() => {
    let defFiels = [];
    if (defaultFileList.length > 0) {
      if (defaultFileList.split(" ").length > 1) {
        defaultFileList.split(" ").map((elem, index) => {
          if (index) {
            defFiels.push({
              uid: index,
              name: index,
              status: "done",
              response: elem, // custom error message to show
              url: "https://89.108.83.230/" + elem,
              thumbUrl: "https://89.108.83.230/" + elem,
            });
          }
        });
      } else {
        [defaultFileList].map((elem, index) => {
          defFiels.push({
            uid: index,
            name: index,
            status: "done",
            response: elem, // custom error message to show
            url: "https://89.108.83.230/" + elem,
            thumbUrl: "https://89.108.83.230/" + elem,
          });
        });
      }
    }
    setFileList(defFiels);
  }, []);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [fileList]
  );
  files(fileList);
  const props = {
    name: "file",
    action: link,
    multiple: multiple,
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    progress: {
      strokeColor: {
        "0%": "#ffb400",
        "100%": "#ffb400",
      },
      showInfo: false,
      strokeWidth: 2,
    },

    onChange(info) {
      // alert(info);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "uploading") {
        setTimeout(() => {
          info.file.status = "done";
        }, 2000);
      }
      if (info.file.status === "remove") {
        files(info.fileList);
        console.log(info.fileList);
        defaultFileList = info.fileList;
        // console.log("--------------");
      }
      if (info.file.status === "done") {
        // message.success(`Успешно`);
        defaultFileList = info.fileList;

        setFile(info.fileList);
        files(info.fileList);
      } else if (info.file.status === "error") {
        // message.error(`Ошибка`);
      }
      // console.log(info.file.status);
    },

    onRemove(file) {
      file.status = "remove";
      //   axios.post(`${process.env.REACT_APP_API_KEY}/api/upload/remove`, {
      //     file: file.response,
      //   });
      // console.log(file);
      setFile(file.fileList);
      files(null);
    },
  };
  const onChange = ({ fileList: newFileList, file }) => {
    setFileList(newFileList);
    switch (file.status) {
      case "uploading":
        setTimeout(() => {
          file.status = "done";
        }, 2000);
        break;
      case "remove":
        files(newFileList);
        console.log(newFileList);
        defaultFileList = newFileList;
        // console.log("--------------");
        break;
      case "done":
        defaultFileList = newFileList;

        setFile(newFileList);
        files(newFileList);
        break;
      default:
        break;
    }
  };
  const onRemove = (file) => {
    file.status = "remove";
    console.log(file);
    // axios.post(`${process.env.REACT_APP_API_KEY}/api/upload/remove`, {
    //   file: file.response,
    // });
    // console.log(file);
    setFile(file.fileList);
    files(null);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <Upload
        action="https://89.108.83.230/api/upload/"
        multiple={true}
        fileList={fileList}
        name="file"
        iconRender={(i) => (
          <IconRender
            link="https://89.108.83.230/"
            src={i.url}
            name={i.response}
          />
        )}
        listType="picture"
        accept=".doc,.pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.jpg,.jpeg,.png,.mp4,.mpeg4,.mov,.heic,.webp"
        onChange={onChange}
        onRemove={onRemove}
        itemRender={(originNode, file, currFileList) => (
          <DragableUploadListItem
            originNode={originNode}
            file={file}
            fileList={currFileList}
            moveRow={moveRow}
          />
        )}
      >
        <Button icon={<PlusCircleOutlined />}>загрузить</Button>
      </Upload>
    </DndProvider>
  );
}
