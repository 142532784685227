import React from "react";
import { DatePicker, Space } from "antd";
import moment from "moment";
function DatePickerPost({ defaultValue = Date.now(), formvalue }) {
  const onChange = (date, dateString) => {
    formvalue(date.format());
  };

  return (
    <div style={{ width: "100%" }} className="andtpickerFormDate">
      <DatePicker
        className="admin-panel__input"
        onChange={onChange}
        defaultValue={moment(defaultValue)}
      />
    </div>
  );
}

export default DatePickerPost;
