import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import "./AdminPanel.css";
import { useDispatch } from "react-redux";
import { setOpen } from "../../store/pageSlice";
import UploadFile from "../UploadFile/UploadFile";
import ReactQuill from "react-quill";
import { modulesQuil } from "./formatsQuill";
const CreateAdvert = () => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [img, setImg] = useState(null);
  const dispath = useDispatch();
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  const [value, setValue] = useState("");
  console.log(errors);
  const onSubmit = async (data) => {
    let files = "";
    img.map((elem) => (files = files + " " + elem.response));
    data.img = files;
    data.desc = value;

    console.log(data);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_KEY}/advert/create`,
        data,
        config
      );
      dispath(setOpen(false));
      navigate("/admin-panel");
    } catch (error) {
      alert(error);
      dispath(setOpen(false));
    }
  };
  return (
    <div className="createApost box_inner blog-post">
      {localStorage.getItem("token") ? (
        <form className="admin-panel" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="blue">Добавить объявление</h1>
          <div className={errors.title ? "err dv" : "dv"}>
            <input
              className={"admin-panel__input"}
              placeholder="Заголовок"
              type="text"
              {...register("title", { required: true })}
            />
          </div>
          <div className={errors.desc ? "err dv" : "dv"}>
            <ReactQuill
              modules={modulesQuil}
              theme="snow"
              className="mb20"
              value={value}
              onChange={setValue}
            />
          </div>
          <div className={errors.document ? "err dv" : "dv"}>
            <input
              className="admin-panel__input"
              placeholder="Тип"
              {...register("type", { required: true })}
            />
          </div>
          <div className={errors.date ? "err dv" : "dv"}>
            <input
              className="admin-panel__input"
              placeholder="Дата"
              {...register("date", { required: true })}
            />
          </div>
          <div className={errors.executor ? "err dv" : "dv"}>
            <input
              className="admin-panel__input"
              placeholder="Контакт"
              {...register("executor", { required: true })}
            />
          </div>
          <div className={errors.linkname ? "err dv" : "dv"}>
            <input
              className="admin-panel__input"
              placeholder="Название ссылки"
              {...register("linkname", { required: true })}
            />
          </div>
          <div className={errors.link ? "err dv" : "dv"}>
            <input
              className="admin-panel__input"
              placeholder="Ссылка"
              {...register("link", { required: true })}
            />
          </div>
          <div className="form-group admupl">
            <UploadFile files={setImg} imgLink={"https://89.108.83.230/"} />
          </div>
          <button type="submit" class="button ssd admbtns">
            <span class="button-text" style={{ color: "#666" }}>
              {" "}
              Отправить
            </span>
            <span class="button-icon fa fa-comment-o"></span>
          </button>
        </form>
      ) : (
        <Link to="/admin">Зайдите в аккаунт</Link>
      )}
    </div>
  );
};

export default CreateAdvert;
