import React from "react";
import HtmlParser from "../HtmlParser";

function TextBlock({ top, bot, text, img }) {
  console.log(top, bot, text, img);
  let style = "quotebox";
  if (top && bot) {
    style += " qwl qwr";
  } else {
    if (top) {
      style += " qwl";
    }
    if (bot) {
      style += " qwr";
    }
  }

  return (
    <div>
      <div className={style}>
        {top ? (
          <div className="icon">
            <img
              src={process.env.REACT_APP_API_KEY + "/" + img}
              alt="blog quote"
            />
          </div>
        ) : (
          ""
        )}
        <HtmlParser style={{ marginTop: "0px" }} html={text} />
        {bot ? (
          <div className="icon2">
            <img
              src={process.env.REACT_APP_API_KEY + "/" + img}
              alt="blog quote"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default TextBlock;
