import React, { useState, useEffect, useRef } from "react";
import style from "./Img.module.css";
import { setOpenGalery, setIdGalery } from "../../store/pageSlice";
import { useSelector, useDispatch } from "react-redux";
import * as Scroll from "react-scroll";
function Img({ img, title = "asdasd", id }) {
  const state = useSelector((state) => state.id.galery);
  const idCard = useSelector((state) => state.id.galeryId);
  const dispatch = useDispatch();
  const [click, setClick] = useState(false);
  const [styleClick, setStyleClick] = useState(false);
  const ref = useRef();
  var scroller = Scroll.scroller;
  let scroll = Scroll.animateScroll;

  useEffect(() => {
    if (idCard === id) {
      console.log(id);
      setClick(true);
    } else {
      setClick(false);
    }
  }, [idCard]);

  useEffect(() => {
    scroller.scrollTo(`skrol--${idCard}`, {
      duration: 1000,
      delay: 100,
      smooth: true,
      containerId: "fase",
      offset: 50,
    });
  }, [ref, click]);

  const cl = () => {
    dispatch(setIdGalery(id));
    setStyleClick(!styleClick);

    if (click) {
      setClick(false);
      dispatch(setOpenGalery(true));
      dispatch(setIdGalery(null));
      setTimeout(() => {
        dispatch(setOpenGalery(false));
      }, 900);
    }
  };

  return (
    <div
      ref={ref}
      style={{ backgroundImage: `URL(${img})` }}
      class={`card card--${1} skrol--${id} ${
        styleClick ? style.cl + " " + style.cli : style.nocl + " " + style.cli
      } ${click ? "card--expanded" : ""} gcard`}
      onClick={(e) => cl()}
    >
      <div class="card__title">{title}</div>
      {/* <h3>
        <span style={{ textAlign: "center" }}>{title}</span>
      </h3> */}
    </div>
  );
}

export default Img;
