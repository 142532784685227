import React, { useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import axios from "axios";
import "antd/dist/antd.min.css";
import IconRender from "./IconRender";

const UploadFile = ({
  files,
  link = "https://89.108.83.230/api/upload/",
  multiple = true,
  defaultFileList = "",
  title = "",
  imgLink,
}) => {
  const [file, setFile] = useState([]);
  const [state, setstate] = useState(false);
  let filesw = [];
  // console.log(defaultFileList);
  let defFiels = [];
  if (defaultFileList.length > 0) {
    if (defaultFileList.split(" ").length > 1) {
      defaultFileList.split(" ").map((elem, index) => {
        if (index) {
          defFiels.push({
            uid: index,
            name: index,
            status: "done",
            response: elem, // custom error message to show
            url: "https://89.108.83.230/" + elem,
            thumbUrl: "https://89.108.83.230/" + elem,
          });
        }
      });
    } else {
      [defaultFileList].map((elem, index) => {
        defFiels.push({
          uid: index,
          name: index,
          status: "done",
          response: elem, // custom error message to show
          url: "https://89.108.83.230/" + elem,
          thumbUrl: "https://89.108.83.230/" + elem,
        });
      });
    }
  }
  console.log(link);
  const props = {
    name: "file",
    action: link,
    multiple: multiple,
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    progress: {
      strokeColor: {
        "0%": "#ffb400",
        "100%": "#ffb400",
      },
      showInfo: false,
      strokeWidth: 2,
    },

    onChange(info) {
      // alert(info);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "uploading") {
        setTimeout(() => {
          info.file.status = "done";
        }, 2000);
      }
      if (info.file.status === "remove") {
        files(info.fileList);
        console.log(info.fileList);
        defaultFileList = info.fileList;
        console.log("--------------");
      }
      if (info.file.status === "done") {
        defaultFileList = info.fileList;

        setFile(info.fileList);
        files(info.fileList);
        setstate(true);
      } else if (info.file.status === "error") {
      }
    },
    defaultFileList: defFiels,
    onRemove(file) {
      file.status = "remove";
      axios.post(`${process.env.REACT_APP_API_KEY}/api/upload/remove`, {
        file: file.response,
      });
      console.log(file);
      setFile(file.fileList);
      files(null);
    },
  };
  return (
    <div className="uplant1">
      {title && <span>{title}</span>}
      <Upload
        {...props}
        iconRender={(i) => (
          <IconRender src={i.url} name={i.response} link={imgLink} />
        )}
        name="file"
        // listType="picture"
        accept=".doc,.pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.jpg,.jpeg,.png,.mp4,.mpeg4,.mov,.heic,.webp"
      >
        <Button icon={<i class="fa fa-plus-circle" aria-hidden="true"></i>}>
          &nbsp;загрузить
        </Button>
      </Upload>
    </div>
  );
};

export default UploadFile;
