import React from "react";
import Video from "../Video";

function IconRender({ src, name, link = "https://89.108.83.230/mails/" }) {
  if (name) {
    // console.log(name);
    let count = name.split(".").length;
    const format = name.split(".")[1];
    // console.log(name.split("."));
    // console.log(src, name);
    switch (format) {
      case "mov":
        return (
          <div className="upvid">
            <Video controls={false} src={link + name} />
          </div>
          // <video className="video-prev">
          //   <source src={src} />
          // </video>
        );
      case "MOV":
        return (
          <div className="upvid">
            <Video controls={false} src={link + name} />
          </div>
          // <video className="video-prev">
          //   <source src={src} />
          // </video>
        );
      case "mp4":
        return (
          // <video className="video-prev">
          //   <source src={src ? src : "https://89.108.83.230/mails/" + name} />
          // </video>
          <div className="upvid">
            <Video controls={false} src={link + name} />
          </div>
        );
      case "MP4":
        return (
          <div className="upvid">
            <Video controls={false} src={link + name} />
          </div>
          // <video className="video-prev">
          //   <source src={src} />
          // </video>
        );
      case "mpeg":
        return (
          <div className="upvid">
            <Video controls={false} src={link + name} />
          </div>
          // <video className="video-prev">
          //   <source src={src} />
          // </video>
        );
      case "pdf":
        return (
          <div
            className="upvid"
            style={{ border: "1px solid #ccc", borderRadius: "5px" }}
          >
            <i
              class="fa fa-file-text"
              style={{ fontSize: "25px" }}
              aria-hidden="true"
            ></i>
          </div>
        );
      case "PDF":
        return (
          <div className="upvid">
            <i
              class="fa fa-file-text"
              style={{ fontSize: "25px" }}
              aria-hidden="true"
            ></i>
          </div>
        );
      case "doc":
        return (
          <div className="upvid">
            <i
              class="fa fa-file-text"
              style={{ fontSize: "25px" }}
              aria-hidden="true"
            ></i>
          </div>
        );
      case "docx":
        return (
          <div className="upvid">
            <i
              class="fa fa-file-text"
              style={{ fontSize: "25px" }}
              aria-hidden="true"
            ></i>
          </div>
        );
      case "DOC":
        return (
          <div className="upvid">
            <i
              class="fa fa-file-text"
              style={{ fontSize: "25px" }}
              aria-hidden="true"
            ></i>
          </div>
        );
      case "DOCX":
        return (
          <div className="upvid">
            <i
              class="fa fa-file-text"
              style={{ fontSize: "25px" }}
              aria-hidden="true"
            ></i>
          </div>
        );
      default:
        return (
          // <div
          //   className="bgUpload"
          //   style={{
          //     backgroundImage: `url(https://89.108.83.230/mails/${name})`,
          //   }}
          // ></div>
          <div className="upvid">
            <img
              style={{
                maxWidth: "48px",
                height: "auto",
                maxHeight: "48px",
                borderRadius: "5px",
              }}
              src={link + name}
              alt=""
            />
          </div>
        );
    }
  } else {
    return (
      <div className="upvid">
        {/* <i
          style={{ fontSize: "32px" }}
          class="fa fa-spinner"
          aria-hidden="true"
        ></i> */}
        <i
          style={{ fontSize: "20px" }}
          class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
        ></i>
      </div>
    );
  }
}

export default IconRender;
