import React from "react";

const Tags = ({ doc, arr }) => {
  let tagArray = doc.split(",");
  console.log(tagArray);
  return (
    <div className="adTags">
      {tagArray.map((i, index) => {
        // console.log(index, i);
        console.log(tagArray[Number(index)]);
        return <div className="atg">{i}</div>;
        // if (tagArray[Number(index)]) {
        //   return <div className="atg">{i.label}</div>;
        // }
      })}
    </div>
  );
};

export default Tags;
