import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./AdminPanel.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Modal from "react-modal";
import cancelImg from "../../assets/img/cancel.svg";
import CreatePost from "./CreatePost";
import CreateDoc from "./CreateDoc";
import CreateAdvert from "./CreateAdvert";
import Post from "./Post";
import { useSelector, useDispatch } from "react-redux";
import { curentId, setOpen } from "../../store/pageSlice";
import Advert from "./Advert";
import Doc from "./Doc";
import Video from "../Video";
import { PlusOutlined } from "@ant-design/icons";

const AdminPanel = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [isAuthDoc, setIsAuthDoc] = useState(false);
  const [isAdvertsDoc, setIsAuthAdverts] = useState(false);
  const [posts, setPosts] = useState([]);
  const [docs, setDocs] = useState([]);
  const [adverts, setAdverts] = useState([]);
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [mod, setMod] = useState(false);
  let subtitle;
  const state = useSelector((state) => state.id.isOpen);
  const dispatch = useDispatch();

  const [element, setElement] = useState("createpost");

  const pagesPopap = {
    createpost: CreatePost,
    createdoc: CreateDoc,
    createadvert: CreateAdvert,
    post: Post,
    advert: Advert,
    doc: Doc,
  };

  const CurentPopap = useMemo(() => pagesPopap[element], [element]);

  function openModal(elem, id = "") {
    setElement(elem);
    setIsOpen(true);
    dispatch(curentId(id));
    dispatch(setOpen(true));
  }
  const closeModal = () => {
    setIsOpen(false);

    dispatch(setOpen(false));
    setMod("1");
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      return navigate("/admin");
    }
  }, []);

  useEffect(() => {
    if (state) {
    } else {
      closeModal();
    }
  }, [state]);

  useEffect(() => {
    const request = async () => {
      if (localStorage.getItem("token")) {
        const bebra = await axios.get(
          `${process.env.REACT_APP_API_KEY}/post/posts`,
          config
        );
        if (bebra.status === 200) {
          setPosts(bebra);
          setIsAuth(true);
        }
      }
    };
    request();
  }, [state]);

  useEffect(() => {
    const request = async () => {
      if (localStorage.getItem("token")) {
        const bebra = await axios.get(
          `${process.env.REACT_APP_API_KEY}/doc/docs`,
          config
        );
        if (bebra.status === 200) {
          setDocs(bebra);
          setIsAuthDoc(true);
        }
      }
    };
    request();
  }, [state]);

  useEffect(() => {
    const request = async () => {
      if (localStorage.getItem("token")) {
        const bebra = await axios.get(
          `${process.env.REACT_APP_API_KEY}/advert/adverts`,
          config
        );
        if (bebra.status === 200) {
          setAdverts(bebra);
          setIsAuthAdverts(true);
        } else {
        }
      }
    };
    request();
  }, [state]);

  return (
    <div className="white ">
      {localStorage.getItem("token") ? (
        <>
          <div className="container">
            {isAuth && isAuthDoc && isAdvertsDoc ? (
              <div className="adminka">
                <Tabs className={"admintabs"}>
                  <div className="admhdr">
                    <Link className="return lad" to="/">
                      <i class="fa hv fa-globe fa-3" aria-hidden="false"></i>
                    </Link>
                    <TabList className="atabs">
                      <Tab className={"adtitlebtn"}>
                        <i
                          class="fa hv fa-newspaper-o blue pointer"
                          aria-hidden="true"
                        ></i>

                        <button
                          className="addAdmin"
                          onClick={() => openModal("createpost")}
                        >
                          <PlusOutlined />
                        </button>
                      </Tab>

                      <Tab className={"adtitlebtn"}>
                        <i
                          class="fa hv fa-file-text blue pointer"
                          aria-hidden="true"
                        ></i>

                        <button
                          className="addAdmin"
                          onClick={() => openModal("createdoc")}
                        >
                          <PlusOutlined />
                        </button>
                      </Tab>

                      <Tab className={"adtitlebtn"}>
                        <i
                          class="fa hv fa-list-alt blue pointer"
                          aria-hidden="true"
                        ></i>
                        <button
                          className="addAdmin"
                          onClick={() => openModal("createadvert")}
                        >
                          <PlusOutlined />
                        </button>
                      </Tab>
                    </TabList>
                    {isAuth ? (
                      <button
                        className="adbtnss"
                        onClick={() => {
                          setIsAuth(false);
                          localStorage.clear();
                          navigate("/admin");
                        }}
                      >
                        <i class="fa hv fa-sign-out" aria-hidden="true"></i>
                      </button>
                    ) : null}
                  </div>
                  <TabPanel>
                    <div className="piproot">
                      {posts.data.map((elem) => {
                        // console.log(elem);
                        return (
                          <div
                            key={elem.id}
                            className={"pitem"}
                            onClick={() => openModal("post", elem.id)}
                          >
                            <div className="cardAdminimg">
                              {elem.img.split(" ")[0] === "" ? (
                                <div className="post-thumb">
                                  <div className="d-block position-relative overflow-hidden">
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${
                                        elem.img.split(" ")[1]
                                      }`}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="post-thumb">
                                  <div className="d-block position-relative overflow-hidden">
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${elem.img}`}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div class="entry-header-admin">
                              <h3>{elem.title}</h3>
                            </div>
                            {/* <button
                              className="editbtna"
                              onClick={() => openModal("post", elem.id)}
                            >
                              <img src={svg}></img>
                            </button> */}
                            {/* <p className="entry-content open-sans-font">
                              {elem.content}
                            </p> */}
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="piproot">
                      {docs.data.map((elem) => {
                        return (
                          <div
                            key={elem.id}
                            className={"pitem"}
                            onClick={() => openModal("doc", elem.id)}
                          >
                            <div className="cardAdminimg">
                              {elem.img.split(" ")[0] === "" ? (
                                <div className="post-thumb">
                                  <div className="d-block position-relative overflow-hidden">
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${
                                        elem.img.split(" ")[1]
                                      }`}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="post-thumb">
                                  <div className="d-block position-relative overflow-hidden">
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${elem.img}`}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div class="entry-header-admin">
                              <h3>{elem.title}</h3>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="piproot">
                      {adverts.data.map((elem) => {
                        return (
                          <div
                            key={elem.id}
                            className={"pitem"}
                            onClick={() => openModal("advert", elem.id)}
                          >
                            <div className="cardAdminimg">
                              {elem.img.split(" ")[0] === "" ? (
                                <div className="post-thumb">
                                  <div className="d-block position-relative overflow-hidden">
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${
                                        elem.img.split(" ")[1]
                                      }`}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="post-thumb">
                                  <div className="d-block position-relative overflow-hidden">
                                    <Video
                                      src={`${process.env.REACT_APP_API_KEY}/${elem.img}`}
                                    />{" "}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div class="entry-header-admin">
                              <h3>{elem.title}</h3>
                            </div>

                            {/* <button
                              className="editbtna"
                              onClick={() => openModal("advert", elem.id)}
                            >
                              <img src={svg}></img>
                            </button> */}
                            <div></div>
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            ) : (
              "Вы не вошли в аккаунт"
            )}
          </div>
        </>
      ) : (
        "Вы не вошли в аккаунт"
      )}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="My dialog"
        className="custom-modal green"
        overlayClassName="custom-overlay light"
        closeTimeoutMS={500}
      >
        <button className="close-modal" onClick={closeModal}>
          <img src={cancelImg} alt="close icon" />
        </button>
        <CurentPopap />
      </Modal>
    </div>
  );
};

export default AdminPanel;
