import { useState, useEffect } from "react";
import axios from "axios";

const AllBlogData = () => {
  const [blogsData, setBlogsData] = useState([]);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const request = await axios.get(
        `${process.env.REACT_APP_API_KEY}/post/posts`
      );
      if (request.status === 200) {
        setBlogsData(request.data);
        setFetched(true);
      }
    };

    fetchData();
  }, []);

  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  return {
    singleData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    fetched,
  };
};

export default AllBlogData;
