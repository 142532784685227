import React from "react";
import Preview from "../views/all-home-version/HomeLight";
import HomeLight from "../views/all-home-version/HomeLight";
import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import ContextProvider from "../Context/ContextProvider";
import Admin from "../components/admin/Admin";
import AdminPanel from "../components/admin/AdminPanel";
import CreatePost from "../components/admin/CreatePost";
import Post from "../components/admin/Post";
import CreateDoc from "../components/admin/CreateDoc";
import Doc from "../components/admin/Doc";
import Advert from "../components/admin/Advert";
import CreateAdvert from "../components/admin/CreateAdvert";
import UploadFile from "../components/UploadFile/UploadFile";

const AllRoutes = () => {
  return (
    <>
      <ContextProvider>
        <Routes>
          <Route path="/" element={<Preview />} />
          <Route path="/home-light" element={<HomeLight />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/admin-panel/createpost" element={<CreatePost />} />
          <Route path="/admin-panel/post/:id" element={<Post />} />
          <Route path="/admin-panel/createdoc" element={<CreateDoc />} />
          <Route path="/admin-panel/doc/:id" element={<Doc />} />
          <Route path="/admin-panel/createdoc" element={<CreateDoc />} />
          <Route path="/admin-panel/doc/:id" element={<Doc />} />
          <Route path="/admin-panel/createadvert" element={<CreateAdvert />} />
          <Route path="/admin-panel/advert/:id" element={<Advert />} />
          <Route path="/upload" element={<UploadFile />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ContextProvider>
    </>
  );
};

export default AllRoutes;
