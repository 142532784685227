import React from "react";
import ReactPlayer from "react-player/lazy";

function Video({ src, res = false, controls = true }) {
  // console.log(src);

  const err = () => {
    console.log("Ошибка");
  };

  return (
    <>
      {src.indexOf(".MOV") > 0 ||
      src.indexOf(".mp4") > 0 ||
      src.indexOf(".MP4") > 0 ||
      src.indexOf(".mpeg4") > 0 ||
      src.indexOf(".webm") > 0 ||
      src.indexOf(".ogv") > 0 ||
      src.indexOf(".mp3") > 0 ||
      src.indexOf(".mov") > 0 ||
      src.indexOf(".m3u8") > 0 ||
      src.indexOf(".mpd") > 0 ? (
        <ReactPlayer
          className="vodr"
          style={{ borderRadius: 5, marginBottom: "-5px", objectFit: "cover" }}
          controls={controls}
          width="100%"
          height="100%"
          url={src}
          onError={err}
        />
      ) : res ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            marginBottom: "-5px",
            backgroundImage: `URL(${src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="imgbg"
        ></div>
      ) : (
        <img src={src} className="img-fluid" alt="item.title" />
      )}
    </>
  );
}

export default Video;
