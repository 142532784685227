import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Compressor from "compressorjs";
import UploadFile from "./UploadFile/UploadFile";
import "./Contact.css";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [img, setImg] = useState(null);
  const [sent, setSent] = useState(false);

  const onSubmit1 = async (data) => {
    let files = [];

    if (img) {
      img.map((i) => {
        files.push(i.response);
      });
    }

    data.img = files;

    await axios.post(`${process.env.REACT_APP_API_KEY}/mail`, data);
    setSent(true);
  };

  return (
    <>
      <form className="contactform" onSubmit={handleSubmit(onSubmit1)}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <textarea
                {...register("text", { required: true })}
                name="text"
                placeholder="Сообщение"
              ></textarea>
              {errors.message && (
                <span className="invalid-feedback">Напишите сообщение.</span>
              )}
              <UploadFile
                files={setImg}
                link={"https://89.108.83.230/api2/upload2"}
              />
            </div>
          </div>
          <div className="col-12">
            <button
              type="submit"
              className={sent ? "sad button ssd" : "button ssd"}
              hover={true}
            >
              <span className="button-text">
                {" "}
                {sent ? <span className="sentb">Отправлено</span> : "Отправить"}
              </span>
              <span className="button-icon fa fa-comment-o"></span>
            </button>
          </div>
          {/* End .col */}
          {/* {sent ? <span className="sent">Отправлено</span> : null} */}
        </div>
      </form>

      {/* End contact */}
    </>
  );
};

export default Contact;
