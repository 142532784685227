import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Admin.css";

const Admin = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [erorr, setError] = useState("");
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      console.log("asdsd");
      navigate("/admin-panel");
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      const user = await axios.post(
        `${process.env.REACT_APP_API_KEY}/auth/login`,
        data
      );
      if (user.status === 200) {
        navigate("/admin-panel");
      }
      localStorage.setItem("token", user.data.token);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error);
    }

    //

    // console.log(user);
  };

  return (
    <div className="white container mada">
      <div className="mainadm">
        {localStorage.getItem("token") ? (
          <div className="blue">
            Вы уже вошли в аккаунт
            <br />
            <Link to="/admin-panel">Перейдите к панели Админа </Link>
          </div>
        ) : (
          <form
            className="admin-panel"
            onSubmit={handleSubmit(onSubmit)}
            style={{ maxWidth: "600px" }}
          >
            <h1>Вход в панель Админа</h1>
            <p>{erorr}</p>
            <input
              className="admin-panel__input"
              placeholder="Логин"
              {...register("email", { required: true })}
            />
            <input
              className="admin-panel__input"
              type="password"
              placeholder="Пароль"
              {...register("password", { required: true })}
            />
            <button type="submit" class="button ssd admbtns">
              <span class="button-text" style={{ color: "#666" }}>
                {" "}
                Отправить
              </span>
              <span class="button-icon fa fa-comment-o"></span>
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Admin;
