import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    value: null,
    isOpen: false,
    galery: false,
    galeryId: null,
  },
  reducers: {
    curentId: (state, actions) => {
      console.log(actions.payload);
      state.value = actions.payload;
    },
    setOpen: (state, actions) => {
      state.isOpen = actions.payload;
    },
    setOpenGalery: (state, actions) => {
      state.galery = actions.payload;
    },
    setIdGalery: (state, actions) => {
      state.galeryId = actions.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { curentId, setOpen, setOpenGalery, setIdGalery } =
  counterSlice.actions;

export default counterSlice.reducer;
