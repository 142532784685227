import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img2 from "../../assets/img/blog/blog-post-1.jpg";
const educationContent = [
  {
    year: "2021",
    degree: (
      <a className="preview-link"
        href="https://kad.arbitr.ru/Card/a0a63765-3c25-43ba-bd31-afa96b1dc66f"
        target="_blank"
      >
        А40-154712/2021
      </a>
    ),
    slider: true,
    imgList: ["img/marshal2021-1.jpg"],
    institute: "снос самостроя",
    details: `Росимущество заявило требование о сносе трех задний самосторя, чтобы в последствие передать землю жителям без спорных объектов.`,
  },  {
    year: "2013",
    degree: (
      <a className="preview-link"
        href="https://mfkmarshal.ru/Doc/Соглашение о реализации госконтракта 6 декабря 2013 года_МО РФ 4.pdf"
        target="_blank"
      >
        № 8/МР
      </a>
    ),
    slider: true,
    imgList: ["img/marshal2013-1.jpg", "img/marshal2013-2.jpg", "img/marshal2013-3.jpg"],
    institute: "реализация госконтракта",
    details: `По соглашению Правительства Москвы и МО РФ ЖК Маршал введен к эксплуатацию полностью. При этом большая часть корпусов еще только строится.`,
  },
  {
    year: "2009",
    degree: (
      <a className="preview-link" href="https://docs.cntd.ru/document/3691551" target="_blank">
        № 1467-РП
      </a>
    ),
    slider: true,
    imgList: ["img/marshal2008-1.jpg", "img/marshal2008-2.jpg"],
    institute: "постройка МФК Маршал",
    details: `Принять предложение Правительства Москвы и Министерства обороны Российской Федерации о комплексной реконструкции территории площадью 5,7 га, занимаемой Федеральным государственным унитарным предприятием "22 бронетанковый ремонтный завод" Министерства обороны Российской Федерации (22 БТРЗ) по адресу: ул. Маршала Рыбалко, вл. 2 (район Щукино, Северо-Западный административный округ), и использовании этой территории под строительство в 2006-2011 годах жилого микрорайона общей площадью 202,0 тыс.кв.м, включая подземную часть, в том числе: наземная часть - 120 тыс. кв.м в составе: 80 тыс.кв.м - жилой комплекс, 40 тыс.кв.м - помещения общественного назначения, включая гостиницу на 70 номеров общей площадью 10 тыс.кв.м, детский сад на 130 мест общей площадью в соответствии с нормами; подземная часть - 82 тыс.кв.м в составе: 63,2 тыс.кв.м автостоянка на 1490 машиномест, 18,8 тыс.кв.м - технические помещения.`,
  },
  {
    year: "2001",
    degree: (
      <a className="preview-link" href="https://docs.cntd.ru/document/3628228" target="_blank">
        № 744-ПП
      </a>
    ),
    slider: true,
    imgList: ["img/marshal2001-1.jpg", "img/marshal2001-2.jpg"],
    institute: "развитие территории СЗАО",
    details: `Постановление Правительства Москвы от 14 августа 2001 года № 744-ПП
	О градостроительном плане развития территории Северо-Западного административного округа до 2020 года - реорганизация территории в районе станции метро "Октябрьское поле", прилегающей к 3-му транспортном кольцу, с высвобождением производственных территорий под жилищное и культурно-бытовое строительство.`,
  },
];

const Education = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
  };
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-file-o"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
		  <p className="open-sans-font mb-3">{val.details}</p>
          {val.slider && (
            <figure className="modal__img">
              <Slider {...settings}>
                {val.imgList.map((i) => (
                  <div className="sliderCard">
                    <img src={i} alt="portfolio project demo" />
                  </div>
                ))}
              </Slider>
            </figure>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Education;
