import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import "./AdminPanel.css";
import { useSelector, useDispatch } from "react-redux";
import { modulesQuil } from "./formatsQuill";
import { setOpen } from "../../store/pageSlice";
import Video from "../Video";
import Tags from "./Tags";
import UploadFile from "../UploadFile/UploadFile";
import HtmlParser from "../HtmlParser";
import ReactQuill from "react-quill";
import { DeleteOutlined } from "@ant-design/icons";
import UploadFileAdmin from "../UploadFile/UploadFileAdmin";
import DatePickerPost from "./DatePickerPost/DatePickerPost";
const monthes = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

const Post = ({ id }) => {
  const dispath = useDispatch();
  const curentId = useSelector((state) => state.id.value);
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [fetched, setFetched] = useState(false);
  const [date, setDate] = useState();
  const params = useParams();
  const [post, setPost] = useState({});
  const [edit, setEdit] = useState(true);
  const [img, setImg] = useState(false);
  const [img2, setImg2] = useState(false);
  const [value, setValue] = useState("");
  const [imgt1, setImgt1] = useState(null);
  const [imgt2, setImgt2] = useState(null);
  const [imgt3, setImgt3] = useState(null);
  const [imgt4, setImgt4] = useState(null);
  const [desk1, setDesk1] = useState("");
  const [desk2, setDesk2] = useState("");
  const [desk3, setDesk3] = useState("");
  const [desk4, setDesk4] = useState("");
  const [dateForm, setDateForm] = useState(post.createdAt);
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  useEffect(() => {
    const request = async () => {
      const responce = await axios.get(
        `${process.env.REACT_APP_API_KEY}/post/${curentId}`
      );
      if (responce.status === 200 && responce.data) {
        setPost(responce.data);
        setValue(responce.data.content);
        setDesk1(responce.data.desk1);
        setDesk2(responce.data.desk2);
        setDesk3(responce.data.desk3);
        setDesk4(responce.data.desk4);
        setImg2(responce.data.img);
        setImgt1(responce.data.imgdesk1);
        setImgt2(responce.data.imgdesk2);
        setImgt3(responce.data.imgdesk3);
        setImgt4(responce.data.imgdesk4);
        setDate(dayjs(post.createdAt).format("DD/MM/YYYY").split("/"));
        setFetched(true);
      } else {
        navigate("/admin-panel");
      }
    };
    request();
  }, []);

  if (!localStorage.getItem("token")) {
    navigate("/admin");
  }

  const deletePost = async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_KEY}/post/delete/${curentId}`,
      config
    );

    // console.log(post.img.split(" "));
    post.img.split(" ").map((i) => {
      axios.post(`${process.env.REACT_APP_API_KEY}/api/upload/remove`, {
        file: i,
      });
    });
    dispath(setOpen(false));
    // navigate("/admin-panel");
  };

  const onSubmit = async (data) => {
    let files = "";
    if (img) {
      data.img = img;
    } else {
      data.img = post.img;
    }
    if (img) {
      data.img.map((elem) => (files = files + " " + elem.response));
      data.img = files;
    } else {
      data.img = img2;
    }
    // data.content = value;
    console.log(imgt2);
    if (imgt1.length > 0) {
      data.imgdesk1 = imgt1[0].response;
    } else {
      console.log("asd");
      data.imgdesk1 = "";
    }
    if (imgt2.length > 0) {
      console.log(imgt2);
      data.imgdesk2 = imgt2[0].response;
    } else {
      data.imgdesk2 = "";
    }
    console.log(imgt3);
    if (imgt3.length > 0) {
      data.imgdesk3 = imgt3[0].response;
    } else {
      data.imgdesk3 = "";
    }
    if (imgt4.length > 0) {
      data.imgdesk4 = imgt4[0].response;
    } else {
      data.imgdesk4 = "";
    }
    data.desk1 = desk1;
    data.desk2 = desk2;
    data.desk3 = desk3;
    data.desk4 = desk4;
    data.createdAt = dateForm;
    console.log(data);
    console.log(dateForm);

    try {
      await axios.put(
        `${process.env.REACT_APP_API_KEY}/post/edit/${curentId}`,
        data,
        config
      );
      dispath(setOpen(false));
    } catch (error) {
      alert(error);
    }

    // navigate("/admin-panel");
  };

  return (
    <>
      <div className="box_inner blog-post createApost ">
        <div className="admin-panel">
          {fetched ? (
            <>
              {edit ? (
                <form className="admin-panel" onSubmit={handleSubmit(onSubmit)}>
                  <h1 className="blueP">Редактирование новости</h1>

                  <input
                    className="admin-panel__input"
                    placeholder={post.title}
                    defaultValue={post.title}
                    {...register("title", { required: true })}
                  />
                  <input
                    className="admin-panel__input"
                    placeholder="Источник"
                    defaultValue={post.company}
                    {...register("company", { required: true })}
                  />
                  <input
                    className="admin-panel__input"
                    placeholder="Тип"
                    defaultValue={post.type}
                    {...register("type", { required: true })}
                  />
                  {/* 
                  <input
                    className="admin-panel__input"
                    placeholder="Дата"
                    defaultValue={post.createdAt}
                    {...register("createdAt", { required: true })}
                  /> */}
                  <DatePickerPost
                    defaultValue={post.createdAt}
                    formvalue={setDateForm}
                  />
                  <ReactQuill
                    modules={modulesQuil}
                    theme="snow"
                    className="mb20"
                    value={desk1}
                    onChange={setDesk1}
                  />
                  <div className="uploadtwoicons">
                    <UploadFile
                      files={setImgt1}
                      multiple={false}
                      imgLink={"https://89.108.83.230/"}
                      defaultFileList={post.imgdesk1}
                    />
                    <div className="dflexmob">
                      <label className="twoIcons">
                        <input
                          className="admin-panel__input"
                          placeholder="Дата"
                          type={"checkbox"}
                          defaultChecked={post.twoicons1}
                          {...register("twoicons1", { required: false })}
                        />
                      </label>
                      <label className="twoIcons">
                        <input
                          className="admin-panel__input"
                          placeholder="Дата"
                          type={"checkbox"}
                          defaultChecked={post.twoicons1bot}
                          {...register("twoicons1bot", { required: false })}
                        />
                      </label>
                    </div>
                  </div>
                  <ReactQuill
                    modules={modulesQuil}
                    theme="snow"
                    className="mb20"
                    value={desk2}
                    onChange={setDesk2}
                  />
                  <div className="uploadtwoicons">
                    {" "}
                    <UploadFile
                      files={setImgt2}
                      multiple={false}
                      defaultFileList={post.imgdesk2}
                      imgLink={"https://89.108.83.230/"}
                    />
                    <div className="dflexmob">
                      <label className="twoIcons">
                        <input
                          className="admin-panel__input"
                          placeholder="Дата"
                          type={"checkbox"}
                          defaultChecked={post.twoicons2}
                          {...register("twoicons2", { required: false })}
                        />
                      </label>
                      <label className="twoIcons">
                        <input
                          className="admin-panel__input"
                          placeholder="Дата"
                          type={"checkbox"}
                          defaultChecked={post.twoicons2bot}
                          {...register("twoicons2bot", { required: false })}
                        />
                      </label>
                    </div>
                  </div>
                  <ReactQuill
                    modules={modulesQuil}
                    theme="snow"
                    className="mb20"
                    value={desk3}
                    onChange={setDesk3}
                  />
                  <div className="uploadtwoicons">
                    {" "}
                    <UploadFile
                      files={setImgt3}
                      multiple={false}
                      defaultFileList={post.imgdesk3}
                      imgLink={"https://89.108.83.230/"}
                    />
                    <div className="dflexmob">
                      <label className="twoIcons">
                        <input
                          className="admin-panel__input"
                          placeholder="Дата"
                          type={"checkbox"}
                          defaultChecked={post.twoicons3}
                          {...register("twoicons3", { required: false })}
                        />
                      </label>
                      <label className="twoIcons">
                        <input
                          className="admin-panel__input"
                          placeholder="Дата"
                          type={"checkbox"}
                          defaultChecked={post.twoicons3bot}
                          {...register("twoicons3bot", { required: false })}
                        />
                      </label>
                    </div>
                  </div>
                  <ReactQuill
                    modules={modulesQuil}
                    theme="snow"
                    className="mb20"
                    value={desk4}
                    onChange={setDesk4}
                  />
                  <div className="uploadtwoicons">
                    <UploadFile
                      files={setImgt4}
                      multiple={false}
                      defaultFileList={post.imgdesk4}
                      imgLink={"https://89.108.83.230/"}
                    />
                    <div className="dflexmob">
                      <label className="twoIcons">
                        <input
                          className="admin-panel__input"
                          placeholder="Дата"
                          type={"checkbox"}
                          defaultChecked={post.twoicons4}
                          {...register("twoicons4", { required: false })}
                        />
                      </label>
                      <label className="twoIcons">
                        <input
                          className="admin-panel__input"
                          placeholder="Дата"
                          type={"checkbox"}
                          defaultChecked={post.twoicons4bot}
                          {...register("twoicons4bot", { required: false })}
                        />
                      </label>
                    </div>
                  </div>

                  {/* <UploadFile
                    files={setImg}
                    defaultFileList={post.img}
                    title={""}
                  /> */}
                  <div className="form-group admupl">
                    <UploadFileAdmin
                      defaultFileList={post.img}
                      files={setImg}
                    />
                  </div>
                  <button type="submit" class="button ssd admbtns">
                    <span class="button-text" style={{ color: "#666" }}>
                      {" "}
                      Отправить
                    </span>
                    <span class="button-icon fa fa-comment-o"></span>
                  </button>
                  {/* <input
                    style={{
                      borderColor: "green",
                    }}
                    type="submit"
                    class="subbtn"
                  /> */}
                </form>
              ) : (
                <div className="adDesk">
                  <h3 className="blue">Название:</h3>
                  <span>{post.title}</span>
                  <p className="blue">Дата создания: </p>
                  <span>
                    {date[0] + " " + monthes[date[1] - 1] + " " + date[2]}
                  </span>
                  <div className="gr-3">
                    <p className="blue">Описание: </p>
                    <HtmlParser html={post.content} />
                  </div>
                  <p className="blue">Автор: </p>
                  <span>{post.company}</span>
                  <p className="blue">Дата: </p>
                  <span>{post.createdAt}</span>
                  <p className="blue">Тип: </p>
                  <Tags doc={post.type} />
                  <div className="pimgAdmin">
                    <p className="blue">: </p>
                    <div className="pimgAdmin_img">
                      {post.img.split(" ").length > 2 ? (
                        post.img.split(" ").map((elem, index) => {
                          if (index) {
                            return (
                              <figure key={index} className="modal__img">
                                <Video
                                  src={`${process.env.REACT_APP_API_KEY}/${elem}`}
                                />
                              </figure>
                            );
                          }
                        })
                      ) : (
                        <Video
                          src={`${process.env.REACT_APP_API_KEY}/${
                            post.img.split(" ")[1]
                          }`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="editbtn">
                <button className="removepst" onClick={() => deletePost()}>
                  <DeleteOutlined />
                </button>
                {/* {edit ? (
                  <>
                    <button
                      className="subbtn"
                      onClick={() => dispath(setOpen(false))}
                    >
                      Отменить
                    </button>
                  </>
                ) : (
                  <button className="subbtn" onClick={() => setEdit(true)}>
                    Редактировать
                  </button>
                )} */}
              </div>
            </>
          ) : (
            "Загрузка..."
          )}
        </div>
      </div>
    </>
  );
};

export default Post;
